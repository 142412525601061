article.cow {
    --cowhead: url(./cow.svg);

    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "name" "number" "badge";
    grid-template-rows: 1fr 3fr 2.3rem;
    align-items: center;
    justify-items: center;

    border: solid white 0.2rem;
    border-radius: 20%;

    background-image: var(--cowhead), var(--good-cow);
    /*For Safari flushing problem*/
    background-image: url(./cow.svg),
        linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    background-blend-mode: overlay, normal;
    will-change: opacity;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: 0.5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}

article.cow.preview:not(.active) {
    opacity: 0.4;
}
article.cow.active {
    box-shadow: 0 5rem 0 0.4rem var(--dashboard-background),
        0 0 0 0.4rem var(--dashboard-background),
        0 0.5rem 0 0.4rem var(--dashboard-background);
}

article.cow > * {
    margin: 0;
    padding: 0;
}
article.cow > div.badge {
    --size: 2rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    width: 100%;
    padding: calc(var(--size) / 10);
    padding-top: 0;
    padding-bottom: calc(var(--size) / 10);
    box-sizing: border-box;
    justify-content: space-between;
}

article.cow > h4 {
    grid-area: number;
    font-size: 2.2em;
    font-weight: 900;
    overflow-x: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    max-width: 100%;
}

.digits-1 article.cow > h4 {
    font-size: 4em;
}
.digits-2 article.cow > h4 {
    font-size: 4em;
}
.digits-3 article.cow > h4 {
    font-size: 2.4em;
}
.digits-4 article.cow > h4 {
    font-size: 2.4em;
}
.digits-5 article.cow > h4 {
    font-size: 2.2em;
    font-weight: 700;
}
.digits-6 article.cow > h4 {
    font-size: 2.2em;
    font-weight: 700;
}

article.cow > h5 {
    grid-area: name;
    font-weight: 500;
    align-self: end;
    font-size: 1em;
}

article.cow.calving {
    background-image: var(--cowhead), var(--calving-cow);
    background-image: url(./cow.svg),
        linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

article.cow.delivery,
article.cow.labor,
article.cow.fever,
article.cow.hypothermia,
article.cow.sensor-fallen,
article.cow.heat {
    background-image: var(--cowhead), var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: url(./cow.svg),
        linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

article.cow.heat {
    background-image: var(--cowhead), var(--heat-cow);
    /*For Safari flushing problem*/
    background-image: url(./cow.svg),
        linear-gradient(180deg, #f59619 0%, #f55f19 100%);
}

article.cow.potential {
    background-image: var(--cowhead), var(--potential-cow);
    /*For Safari flushing problem*/
    background-image: url(./cow.svg),
        linear-gradient(180deg, #3fd1ff 0%, #2d9cdb 100%);
}

article.cow.untracked,
article.cow.dead,
article.cow.pending,
article.cow.tsens-problem {
    background-image: var(--cowhead), var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: url(./cow.svg),
        linear-gradient(180deg, #35505a 0%, #556970 100%);
}

article.cow.due {
    background-image: var(--cowhead), var(--due-cow);
    background-image: url(./cow.svg),
        linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

article.cow.overdue {
    background-image: var(--cowhead), var(--bad-cow-handled);
    background-image: url(./cow.svg),
        linear-gradient(180deg, #ac2e10 0%, #551717 100%);
}

article.cow.untracked,
article.cow.dead {
    opacity: 0.5;
}

@media (min-width: 25rem) {
    article.cow {
        width: 7rem;
        height: 7rem;
    }
}

@media (min-width: 52rem) {
    article.cow {
        width: 8rem;
        height: 8rem;
        border: solid white 0.25rem;
    }
    .digits-2 article.cow > h4 {
        font-size: 4em;
    }
    .digits-3 article.cow > h4 {
        font-size: 3.6em;
    }
    .digits-4 article.cow > h4 {
        font-size: 3em;
    }
    .digits-5 article.cow > h4 {
        font-size: 2.7em;
        font-weight: 700;
    }
    .digits-6 article.cow > h4 {
        font-size: 2.7em;
        font-weight: 700;
    }

    article.cow div.badge {
        --size: 2.5rem;
        padding: calc(var(--size) / 4);
        padding-top: 0;
        padding-right: calc(var(--size) / 12);
        padding-left: calc(var(--size) / 12);
    }
}
