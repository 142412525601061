li.event.read > h6,
li.event.marked > h6 {
    opacity: 0.6;
}

li.event {
    --warning: url(../../../assets/Warning.svg);
    --thermometer: url(../../../assets/Thermometer.svg);
    --tsens: url(../../../assets/Tsens.svg);
    --calf: url(../../../assets/Calf.svg);
    --note: url(../../../assets/Note.svg);

    --sidebar-gradient: var(--dead-cow);
    --pictogram: var(--warning);
    background: var(--dashboard-widget-background);
    margin: 0;
    padding: 0.2rem 0;
    border-bottom: solid 1px var(--dashboard-background);
    padding-left: 1rem;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(../../../assets/Warning.svg);
    background-size: 1rem 100%, 3rem;
    background-position: left, 1rem;
    background-repeat: no-repeat;

    min-height: 2rem;
    max-height: 10rem;

    display: grid;

    grid-template-columns: 3rem 1fr 1rem;
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
        "pictogram message  markbutton"
        "pictogram timestamp  markbutton"
        "action-buttons action-buttons markbutton"
        "confirmed confirmed  markbutton"
        "smartnote smartnote  markbutton";

    animation-name: pop-event;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;

    transform-origin: top;
    /*it breaks functionality of z index */
    /* transform: rotateX(0); */
    /* overflow: hidden; */
}
li.event.unfocused {
    opacity: 0.5;
}
li.event.close {
    min-height: 0.1rem;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
}
li.event.open {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(../../../assets/Warning.svg);
}
li.event.half-open {
    grid-template-areas: "pictogram message timestamp markbutton";
    grid-template-columns: 3rem 1fr auto 1rem;
    grid-template-rows: 2rem;
    max-height: 2rem;
    background-size: 1rem 100%, 2.5rem;
    background-position: left, 1.3rem;
}
li.event.half-open > h6 {
    grid-area: message;
    align-self: center;
}
li.event.half-open > time {
    align-self: center;
}
li.event.smart-note-submitted {
    grid-template-rows: 2fr;
}
li.event.smart {
    background-position: left, left 1rem top 1rem;
    min-height: 8rem;
    max-height: min-content;
    /* max-height: 20rem; */
}

li.event > .smart-note {
    grid-area: smartnote;
    width: 82%;
    margin-left: 3rem;
}
li.event > h6 {
    font-weight: inherit;
    font-size: inherit;
    grid-area: message;
    margin: 0;
    align-self: end;
    overflow: hidden;
}

li.event > h6 > blockquote {
    margin: 0;
    padding: 0;
}

li.event > time {
    opacity: 0.8;
    font-size: 0.8rem;
    grid-area: timestamp;
    display: block;
    margin: 0;
    align-self: start;
}
li.event > button.mark-read {
    grid-area: markbutton;
    align-self: center;
    justify-self: right;
}
li.event > div.action-buttons {
    margin-left: 3rem;
    margin-right: 1rem;
    margin-top: 0.3rem;
    grid-area: action-buttons;

    display: flex;
    grid-column-gap: 0.2rem;
}
li.event.potential-calvings-detected > div.action-buttons {
    grid-template-areas: "button";
    grid-template-columns: 1fr;
}
li.event > div.action-buttons button.action {
    align-self: stretch;
    justify-self: start;
    background-color: var(--dashboard-background);
    color: var(--dashboard-text-color);
    border: none;
    border-radius: 0.25em;
    transition-property: opacity, box-shadow;
    transition-duration: 0.25s;
    outline: none;
    margin: 0;
    width: 100%;
    font-size: 0.8rem;
}

li.event > span.confirmed {
    grid-area: confirmed;
    margin-left: 3rem;
    font-size: 0.8em;
    text-decoration: underline;
}

li.event > button.visible {
    visibility: visible;
}

li.event > button.action.done {
    opacity: 0.2;
    text-decoration: line-through;
}

@media (hover: hover) {
    li.event > div.action-buttons button.action {
        opacity: 0.5;
    }
    li.event > div.action-buttons button.action:enabled:hover {
        opacity: 1;
        box-shadow: currentColor 0 0 0 0.25em;
    }
}

@media (pointer: fine) {
    li.event > button.action {
        align-self: center;
        padding: 0.5em;
    }
}
li.event.dashed {
    grid-template-areas: "dashed";
    grid-template-columns: 100%;
    min-height: 0;
    height: 0;
    background-image: linear-gradient(
        180deg,
        #4f4f4f 0%,
        #4f4f4f 100%
    ) !important;
    background-color: var(--dashboard-background) !important;
    padding-top: 0.1rem;
}
li.event.dashed h6 {
    grid-area: dashed;
    border: 0.1rem dashed var(--dashboard-widget-background);
    width: 100%;
}

li.event.potential-calvings-detected {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(../../../assets/Note.svg);
}

li.event.tsens-problem-detected.close,
li.event.tsens-installed.close,
li.event.session-created.close,
li.event.session-completed.close,
li.event.tsens-problem-over.close,
li.event.tsens-removed.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.event.tsens-problem-detected.earTag.close,
li.event.tsens-installed.earTag.close,
li.event.session-created.earTag.close,
li.event.session-completed.earTag.close,
li.event.tsens-problem-over.earTag.close,
li.event.tsens-removed.earTag.close,
li.event.fever-over.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.event.hypothermia-detected.close,
li.event.hypothermia-over.close,
li.event.fever-detected.close {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.event.calving-detected.close {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

li.event.calving-due.close {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

li.event.labor-detected.close,
li.event.delivery-detected.close,
li.event.delivery-completed.close,
li.event.calving-overdue.close,
li.event.heat-detected.close,
li.event.sensor-fall-detected.close {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.event.calving-completed.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
li.event.calving-completed.open {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/Calf.svg);
}

li.event.note-submitted.close,
li.event.smart-note-submitted.close {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
}
li.event.note-submitted.open,
li.event.smart-note-submitted.open,
li.event.called-user-activated {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(../../../assets/Note.svg);
}
li.event.tsens-problem-detected,
li.event.fever-detected,
li.event.hypothermia-detected,
li.event.labor-detected,
li.event.delivery-detected,
li.event.calving-overdue,
li.event.heat-detected,
li.event.sensor-fall-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(../../../assets/Warning.svg);
}

li.event.calving-due {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%),
        url(../../../assets/Warning.svg);
}

li.event.calving-detected {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%),
        url(../../../assets/Warning.svg);
}

li.event.tsens-installed,
li.event.session-created,
li.event.session-completed,
li.event.tsens-problem-over,
li.event.fever-over,
li.event.hypothermia-over,
li.event.delivery-completed,
li.event.calving-completed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/Warning.svg);
}

li.event.tsens-problem-detected,
li.event.tsens-installed,
li.event.session-created,
li.event.session-completed,
li.event.tsens-problem-over,
li.event.tsens-removed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/Tsens.svg);
}

li.event.tsens-problem-detected.earTag,
li.event.tsens-installed.earTag,
li.event.session-created.earTag,
li.event.session-completed.earTag,
li.event.tsens-problem-over.earTag,
li.event.tsens-removed.earTag {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/EarTag.svg);
}

li.event.hypothermia-detected,
li.event.hypothermia-over,
li.event.fever-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(../../../assets/Thermometer.svg);
}

li.event.fever-over {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/Thermometer.svg);
}

li.event.calving-detected {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%),
        url(../../../assets/Calf.svg);
}

li.event.calving-due {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%),
        url(../../../assets/Calf.svg);
}

li.event.labor-detected,
li.event.delivery-detected,
li.event.delivery-completed,
li.event.calving-overdue {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(../../../assets/Calf.svg);
}

li.event.heat-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(../../../assets/estrous.svg);
}
li.event.calving-completed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(../../../assets/Calf.svg);
}

li.event.note-submitted,
li.event.smart-note-submitted {
    max-height: min-content;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(../../../assets/Note.svg);
}
@keyframes pop-event {
    from {
        min-height: 0;
        max-height: 0;
        transform: rotateX(90deg);
    }
}
