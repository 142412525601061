@import url("https://use.typekit.net/lna6dbu.css");

body {
    margin: 0;
    padding: 0;
    --dashboard-background: #333;
    --dashboard-widget-background: #4f4f4f;
    --dashboard-text-color: #f7f7f7;
    --dashboard-box-border: #e0e0e0;
    background: var(--dashboard-background);
    color: var(--dashboard-text-color);
    font-family: "lato", sans-serif;

    --button-action-color: #2d9cdb;
    --checked-color: #2d9cdb;

    --signal-color: #f2c94c;

    --tag-color: #2e4759;
    --tag-parameter-color: rgb(54, 109, 67);
    --tag-logic-color: #a73d3d;
    --tag-sort-color: #2e4759;

    --good-cow: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    --weird-cow: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --bad-cow: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    --bad-cow-handled: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    --dead-cow: linear-gradient(180deg, #35505a 0%, #556970 100%);
    --unstable-cow: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --heat-cow: linear-gradient(180deg, #f59619 0%, #f55f19 100%);
    --potential-cow: linear-gradient(180deg, #3fd1ff 0%, #2d9cdb 100%);
    --calving-cow: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    --due-cow: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);

    --idle-sensor: linear-gradient(180deg, #35505a 0%, #556970 100%);
    --stable-sensor: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    --unstable-sensor: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --bad-sensor: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);

    --diagonal-stripes: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
    );
}

.navigating {
    pointer-events: none;
}

.placeholder {
    background-image: var(--diagonal-stripes);
    /*For Safari flushing problem*/
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
    );
}

[hidden] {
    display: none !important;
}

@media (min-width: 52rem) {
    body {
        overflow: hidden;
    }
}
