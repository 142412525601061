button.new-pairing-list {
    --plus: url(./Plus.svg);
    
    width: 6.8rem;
    height: 6.8rem;

    border: none;
    border-radius: 20%;

    background-image: url(./Plus.svg);

    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%, contain;

    box-sizing: content-box;
    padding: 0;
    
    opacity: .4;

    transition-property: opacity, background-image;
    transition-duration: .3s;
    
    outline: none;
}

button.new-pairing-list.disabled {
    cursor: not-allowed;
    /* opacity: .3; */
}

div.loader {
    width: 6.8rem;
    height: 6.8rem;
}

@media(min-width: 25rem) {
    button.new-pairing-list {
        width: 7.2rem;
        height: 7.2rem;
    }
}

@media(min-width: 52rem) {
    button.new-pairing-list {
        width: 8.8rem;
        height: 8.8rem;
    }
}
