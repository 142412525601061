ul.cow-list {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 2px var(--dashboard-background);
}
li.cow {
    position: relative;
    display: grid;
    grid-template-areas:
        "farmnumber name breed-age sensors tags"
        "farmnumber name lactation sensors tags"
        "identity identity lactation sensors tags";
    grid-template-columns: min-content 10rem 7.8rem 5.5rem 9rem;
    grid-template-rows: 2rem auto 2rem;
    column-gap: 0.25rem;

    background-color: var(--dashboard-widget-background);
    padding: 0.5em 1em;
    padding-left: 3em;
    border-left: solid 10px var(--dashboard-widget-background);
    border-right: solid 10px var(--dashboard-widget-background);

    background-image: url(./CowPictogram.svg);
    background-repeat: no-repeat;
    background-size: 2em;
    background-position: center left 0.5em;
    background-blend-mode: overlay;
    border-bottom: solid 1px var(--dashboard-background);
}
li.cow.first {
    border-top: solid 2px var(--dashboard-background);
}

li.cow.selected {
    background-blend-mode: normal;
    border-left: solid 10px var(--checked-color);
    border-right: solid 10px var(--checked-color);
    /* For Safari */
    background-blend-mode: color-dodge;
}

li.cow * {
    font-size: 0.8rem;
    font-weight: 300;
    list-style: none;
    margin: 0;
    padding: 0;
    align-self: start;
    cursor: default;
}

li.cow strong {
    font-weight: 700;
}
li.cow > div.options {
    grid-area: options;
    background: url(./../../assets/burgerNav.svg) no-repeat;
    background-position: center;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
}

li.cow > h1 {
    font-size: 1.6rem;
    font-weight: 700;
    grid-area: farmnumber;
    justify-self: start;
}

li.cow > h2 {
    font-size: 1.6em;
    grid-area: name;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
li.cow .tooltip-common .content h5 {
    font-size: 1.2em;
    font-weight: 300;
    padding: 0.1rem 0.4rem 0.1rem 0.4rem;
    cursor: pointer;
}
li.cow .tooltip-common .content h5:hover {
    background-color: var(--button-action-color);
}
li.cow .tooltip-common .content h5:hover:last-child {
    /* border-radius: 0 0 .4rem .4rem; */
}

li.cow > ul > li {
    display: inline-block;
    margin-right: 1rem;
}

li.cow > h3.identity {
    grid-area: identity;
}
li.cow > h3.breed-age {
    grid-area: breed-age;
    justify-self: start;
}
li.cow > h3.lactation {
    grid-area: lactation;
    justify-self: start;
}

li.cow > ul.sensors {
    grid-area: sensors;
    justify-self: start;
}
li.cow > ul.tags {
    grid-area: tags;
    justify-self: start;
}
li.cow > h1 {
    pointer-events: none;
}
li.cow > h3 {
    pointer-events: none;
}

li.cow > ul.sensors li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
li.cow > ul.sensors li.stable:before {
    background-image: var(--good-cow);
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
li.cow > ul.sensors li.unstable:before {
    background-image: var(--bad-cow);
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.cow > ul.tags > li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}

li.cow > ul.tags > li.due:before {
    background-image: var(--due-cow);
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

li.cow > ul.tags > li.tracked:before,
li.cow > ul.tags > li.pregnant:before {
    background-image: var(--good-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.cow > ul.tags > li.calving:before {
    background-image: var(--calving-cow);
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

li.cow > ul.tags > li.hypothermia:before,
li.cow > ul.tags > li.fever:before {
    background-image: var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
li.cow > ul.tags li strong {
    margin-right: 0.5rem;
}
li .cow-options-menu {
    display: none;
    visibility: hidden;
    position: absolute;
    z-index: 10;
    padding: 0.2rem 0;
    max-width: max-content;
    width: 100%;
    background-color: var(--dashboard-background);
    border: solid 1px var(--dashboard-box-border);
    border-radius: 0.5rem;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5), 0 0 1rem black;
}
li .cow-options-menu.active {
    visibility: visible;
    display: block;
}

li.cow > nav > .options {
    list-style: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
li.cow > nav > ul > .option {
    font-size: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 0.5rem;
    background-size: 2rem;
    background-position: left;
    background-repeat: no-repeat;
    margin-bottom: 4px;
}
li.cow > nav > .options .edit {
    --edit: url("../../assets/Edit.svg");
    background-image: var(--edit);
}
li.cow > nav > .options .remove {
    --remove: url("../../assets/Remove.svg");
    background-image: var(--remove);
}
li.cow > nav > ul > .option:last-child {
    margin-bottom: 0;
}
li.cow > nav > ul > .option:hover {
    cursor: pointer;
    background-color: var(--button-action-color);
}
@media (max-width: 35rem) {
    ul.cow-list {
        overflow-x: hidden;
        overflow-y: auto;
        border-top: solid 2px var(--dashboard-background);
        border-bottom: solid 2px var(--dashboard-background);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
    li.cow {
        display: grid;
        grid-template-areas:
            "farmnumber name . tags"
            "identity identity . tags"
            "identity identity . tags";
        grid-template-columns: auto 11fr 1fr 7.8rem;
        grid-template-rows: 1rem auto 1rem;
        column-gap: 0.25rem;

        background-color: var(--dashboard-widget-background);
        padding: 0.5em 1em;
        padding-left: 3em;
        border-left: solid 10px var(--dashboard-widget-background);
        border-right: solid 10px var(--dashboard-widget-background);

        background-image: url(./CowPictogram.svg);
        background-repeat: no-repeat;
        background-size: 2em;
        background-position: center left 0.5em;
        background-blend-mode: overlay;
        border-bottom: solid 1px var(--dashboard-background);
        row-gap: 0.5em;
    }
    li.cow.first {
        border-top: none;
    }
    li.cow-details {
        display: grid;
        grid-template-areas:
            "breed-age-lactation breed-age-lactation"
            "sensors ."
            "sensors .";

        grid-template-columns: 6rem 6rem;
        grid-template-rows: 1rem auto 1rem;
        column-gap: 0.5em;
        row-gap: 0.5em;
        padding: 0.3rem 1rem;
        margin-left: 24.2%;
        border-left: solid 5px var(--dashboard-background);
    }
    li.cow-options {
        display: grid;
        grid-template-areas: "edit remove";
        column-gap: 0.5rem;
        padding: 0.3em 0.3em;
        margin-right: 55%;
        border-right: solid 5px var(--dashboard-background);
    }
    li.cow * {
        font-size: 0.7rem;
    }
    li.cow-details * {
        font-size: 0.7rem;
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        align-self: start;
    }
    li.cow-options * {
        font-size: 0.7rem;
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        align-self: start;
    }
    li.cow > h1 {
        font-size: 1.3em;
    }
    li.cow > h2 {
        font-size: 1.3em;
        font-weight: 300;
    }
    li.cow > ul.tags > li {
        font-size: 0.8rem;
        margin-right: 0;
    }
    li.cow > ul.tags > li:before {
        margin-bottom: 0.15rem;
    }
    li.cow-details > ul.sensors li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    li.cow-details > ul.sensors li.stable:before {
        background-image: var(--good-cow);
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    li.cow-details > ul.sensors li.unstable:before {
        background-image: var(--bad-cow);
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }

    li.cow > ul.tags > li > strong {
        font-size: 0.8rem;
        font-weight: 400;
    }
    li.cow > div.options {
        display: none;
    }
    li.cow > h3.identity {
        font-size: 0.8em;
    }
    li.cow-details > h3.breed-age-lactation {
        grid-area: breed-age-lactation;
        font-size: 0.7rem;
        font-weight: 300;
        justify-self: start;
    }
    li.cow-details > h3.breed-age-lactation strong {
        font-weight: 600;
        margin-left: 0.3rem;
    }
    li.cow-details > ul.sensors > li > strong {
        font-weight: 600;
    }
    li.cow-details > ul.sensors {
        grid-area: sensors;
        justify-self: start;
    }
    li.cow-options > button {
        padding: 0;
        margin: 0;
        padding-top: 1.8rem;
        font-size: 0.9rem;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 2rem;
        background-color: var(--dashboard-background);
        border: solid 1.4px var(--dashboard-box-border);
        height: 3rem;
        width: auto;
    }
    li.cow-options > .edit {
        grid-area: edit;
        --edit: url("../../assets/Edit.svg");
        background-image: var(--edit);
    }
    li.cow-options > .remove {
        grid-area: remove;
        --remove: url("../../assets/Remove.svg");
        background-image: var(--remove);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 52rem) {
    ul.cow-list {
        padding-top: 1rem;
        overflow-y: auto;
        border-bottom: solid 2px var(--dashboard-background);
    }
    li.cow {
        grid-template-areas:
            "farmnumber name breed-age sensors tags"
            "farmnumber name lactation sensors tags"
            "identity identity lactation sensors tags";
        grid-template-columns: min-content 9rem 7.8rem 5.5rem 9rem;
        grid-template-rows: 2rem auto 2rem;
    }
    li.cow > h1 {
        pointer-events: none;
    }
    li.cow > h3 {
        pointer-events: none;
    }
    li.cow > h3.breed-age {
        justify-self: start;
    }
    li.cow > h3.lactation {
        justify-self: start;
    }

    li.cow > ul.sensors {
        justify-self: start;
        pointer-events: none;
    }
    li.cow > ul.tags {
        justify-self: start;
        pointer-events: none;
    }
    li.cow > h2 {
        margin-right: 1rem;
        pointer-events: none;
    }
    li.cow > ul > li {
        display: block;
        margin-right: 0;
        pointer-events: none;
    }
}
