form.note {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    display: grid;
    grid-template-areas: "field button";
    grid-template-columns: 1fr 2rem;
    grid-template-rows: 2rem;
    grid-gap: .5rem;
}

form.note input[type="text"] {
    background: var(--dashboard-background);
    color: inherit;
    border: solid 1px gray;
    border-radius: .2rem;
    padding: 0 .5rem;
    grid-area: field-start / field-start / button-end / button-end;
    outline: none;
    transition: border-color 1s;
}

form.note input[type="text"]:focus {
    border-color: white;
}
form.note.failed input[type="text"] {
    box-shadow: 0 0 0 3px #d30;
}

form.note input[type="submit"] {
    grid-area: button;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 1px white;
    border-radius: .1rem;
    background-color: transparent;
    background-image: url(./Envelope.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    transition-property: all;
    transition-duration: .5s;
    align-self: center;
    justify-self: center;
    outline: none;
    opacity: 0.3
}


form.note:valid input[type="submit"] { opacity: .7; }
form.note:valid input[type="submit"]:hover { opacity: 1; }

form.note.pending input[type="submit"] {
    border-radius: 50%;
    background-size: 0;
    border-style: dashed;
    border-color: #379;
    border-width: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    animation: rotation linear 2s infinite;
}

form.note.pending input[type="text"] {
    transition: all .5s ease-in;
    padding-left: 90%;
    overflow-x: hidden;
    color: transparent;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
