section.cows {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(6rem, 1fr));
    place-items: center;
    grid-gap: 1rem;
    padding: 1rem;
    grid-auto-flow: row dense;
}

@media(min-width: 52rem) {
    section.cows {
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
    }
    section.cows aside.cow-preview{
        height: auto;
    }
}
