div.data-graph {
    display: grid;
    grid-template-columns: repeat(2, min-content 1fr) min-content;
    justify-items: center;
    width: 100%;
    height: 100%;
}

div.data-graph > svg {
    grid-column: 1 / span 5;
    grid-row: 1 / span 1;
    fill: none;
    width: 100%;
    height: 100%;
}

div.data-graph path.thermal-gradient {
    fill: url(#thermal-gradient);
}

div.data-graph path.nominal-line,
div.data-graph path.data-line {
    stroke: var(--dashboard-text-color);
    stroke-width: 0.05rem;
    shape-rendering: geometricPrecision;
    stroke-linecap: round;
    vector-effect: non-scaling-stroke;
}

div.data-graph path.data-line.environment {
    stroke: var(--dashboard-text-color);
    stroke-width: 0.15rem;
    stroke-dasharray: 0, 5;
    opacity: 0.5;
    stroke-linecap: round;
}

div.data-graph path.data-line.activity {
    stroke: var(--signal-color);
    stroke-width: 0.1rem;
    shape-rendering: geometricPrecision;
    stroke-linecap: round;
    opacity: 0.7;
    vector-effect: non-scaling-stroke;
}

div.data-graph path.nominal-line {
    stroke-dasharray: 1 2;
}

div.data-graph svg#tooltip {
    z-index: 98;
}
div.data-graph svg#tooltip g .circle {
    fill: var(--dashboard-text-color);
    cursor: pointer;
}

div.data-graph svg#tooltip g.Tsens,
div.data-graph svg#tooltip g.earTag {
    visibility: hidden;
}

div.data-graph svg#tooltip g.visible {
    visibility: visible;
}

div.data-graph text.hover-text {
    fill: var(--dashboard-text-color);
    /* text-rendering: optimizeLegibility; */
    font-weight: 500;
    paint-order: stroke fill;
    stroke: var(--dashboard-background);
    stroke-width: 0.5em;
    /* For IOS & Safari flushing problem */
    background-color: var(--dashboard-background);
}

div.data-graph time {
    font-size: 0.8rem;
    font-weight: 300;
    grid-row: 1 / span 1;
    align-self: end;
    padding: 0.2em;
    white-space: nowrap;
}

div.data-graph time:nth-of-type(1) {
    grid-column-start: 1;
}
div.data-graph time:nth-of-type(2) {
    grid-column-start: 2;
    visibility: hidden;
}
div.data-graph time:nth-of-type(3) {
    grid-column-start: 3;
}
div.data-graph time:nth-of-type(4) {
    grid-column-start: 4;
    visibility: hidden;
}
div.data-graph time:nth-of-type(5) {
    grid-column-start: 5;
}

@media (min-width: 30rem) and (max-width: 40rem), (min-width: 52rem) {
    div.data-graph time:nth-of-type(2n) {
        visibility: visible;
    }
}

div.data-graph data.temperature {
    grid-row: 1 / span 1;
    grid-column: 1 / span 5;
    font-size: 2rem;
    padding: 0.1rem;
    place-self: start end;
    font-kerning: normal;
    pointer-events: none;
}
div.data-graph data time {
    display: grid;
    align-self: start;
}
div.data-graph > time {
    pointer-events: none;
}
div.data-graph data.temperature.high {
    align-self: end;
    margin-bottom: 1rem;
}

div.data-graph data.temperature::after {
    content: "°C";
    font-weight: lighter;
    letter-spacing: -0.1em;
    margin-right: 0.1em;
}

svg#tooltip svg#tooltip-clicked:hover {
    cursor: grab;
}
svg#tooltip svg#tooltip-clicked:active {
    cursor: grabbing;
}

svg#tooltip svg#tooltip-clicked div.content div {
    display: grid;
    grid-template-areas: "tsens" "eartag" "time";
    grid-template-rows: 2.4rem 2.4rem 1fr;
    background-repeat: no-repeat;
}

svg#tooltip svg#tooltip-clicked div.content div time {
    grid-area: time;
    justify-self: end;
    font-size: 1em;
    margin-right: 0.4rem;
}
svg#tooltip svg#tooltip-clicked div.content span {
    font-size: 1.4em;
}

svg#tooltip svg#tooltip-clicked div.content div.Tsens {
    grid-area: tsens;
    background-image: url(./../../assets/Tsens.svg);
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
    font-weight: 600;
}
svg#tooltip svg#tooltip-clicked div.content div.sensor.Tsens span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
}
svg#tooltip svg#tooltip-clicked div.content div.earTag {
    grid-area: eartag;
    background-image: url(./../../assets/EarTag.svg);
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
}
svg#tooltip svg#tooltip-clicked div.content div.sensor.earTag span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
    font-weight: 300;
}

svg#tooltip svg#tooltip-clicked div.content div.environment {
    background-image: url(./../../assets/OutsideTemp.svg);
    background-size: 1.5rem;
    background-position: 1.2rem 1rem;
    font-weight: 300;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.environment span {
    justify-self: center;
    padding-top: 0.8rem;
    padding-left: 2rem;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.activity {
    grid-area: eartag;
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.activity span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
    font-weight: 300;
}

@media (min-width: 20rem) and (max-width: 40rem) {
    div.data-graph data.temperature {
        font-size: 1.4em;
    }
}
@media (min-width: 40rem) and (max-width: 52rem), (min-width: 72rem) {
    div.data-graph data.temperature {
        grid-column: 6 / span 1;
    }
    div.data-graph data.temperature.high {
        align-self: start;
    }
    div.data-graph data.temperature.mid {
        align-self: center;
    }
    div.data-graph data.temperature.low {
        align-self: end;
    }
}

/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @media {
        div.data-graph {
            height: 8.6rem;
        }
    }
}
