main.notification-settings {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    overflow-y: auto;
    grid-gap: .5rem;
    padding: 1rem;
    grid-template-areas:"header close-button"
                        "explanation explanation"
                        "vacation-mode  vacation-mode"
                        "calving-due calving-due"
                        "imminent-calving imminent-calving"
                        "calving-overdue calving-overdue"
                        "fever-hypothermia fever-hypothermia";
    grid-template-columns: auto 8rem;
    grid-template-rows: 4.5rem 2.5rem 7.5rem 8rem 9rem 9rem 6rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, .5)
}
main.notification-settings .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-left: 5.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    cursor: pointer;
}
main.notification-settings h1.header{
    grid-area: header;
    margin: 0;
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.notification-settings > div.explanation{
    grid-area: explanation;
    font-size: .9em;
    font-weight: 300;
}
main.notification-settings > div .buttons{
    grid-area: buttons;
    display: flex;
}
main.notification-settings > .vacation-mode{
    grid-area: vacation-mode;
    display: grid;
    grid-template-areas:    "vacation-header vacation-header" 
                            "vacation-button vacation-mode-explanation"
                            "vacation-button vacation-mode-explanation";
    grid-template-columns: 5rem 1fr;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
 }
 main.notification-settings > .calving-due{
    grid-area: calving-due;
    display: grid;
    grid-template-areas:    "calving-due-header calving-due-header calving-due-header calving-due-header"
                            "buttons buttons buttons buttons"
                            "calving-due-explanation calving-due-explanation calving-due-explanation calving-due-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .imminent-calving{
    grid-area: imminent-calving;
    display: grid;
    grid-template-areas:    "imminent-calving-header imminent-calving-header imminent-calving-header imminent-calving-header"
                            "buttons buttons buttons buttons"
                            "imminent-calving-explanation imminent-calving-explanation imminent-calving-explanation imminent-calving-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .calving-overdue{
    grid-area: calving-overdue;
    display: grid;
    grid-template-areas:    "calving-overdue-header calving-overdue-header calving-overdue-header calving-overdue-header"
                            "buttons buttons buttons buttons"
                            "calving-overdue-explanation calving-overdue-explanation calving-overdue-explanation calving-overdue-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-row-gap: .5rem;
    grid-column-gap: 0.1rem;
 }
 main.notification-settings > .fever-hypothermia{
    grid-area: fever-hypothermia;
    display: grid;
    grid-template-areas:    "fever-hypothermia-header fever-hypothermia-header fever-hypothermia-header fever-hypothermia-header"
                            "buttons buttons buttons buttons"
                            "fever-hypothermia-explanation fever-hypothermia-explanation fever-hypothermia-explanation fever-hypothermia-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .vacation-mode > .vacation-header{
    grid-area: vacation-header;
    font-size: 2rem;
    font-weight: 300;
     }
main.notification-settings > .vacation-mode > .vacation-button{ grid-area: vacation-button; }

main.notification-settings > .calving-due > .calving-due-header{
    font-size: 2rem;
    font-weight: 300;
    grid-area: calving-due-header;
 }
main.notification-settings > .imminent-calving > .imminent-calving-header{
    font-size: 2rem;
    font-weight: 300;
    grid-area: imminent-calving-header; 
}
main.notification-settings > .calving-overdue > .calving-overdue-header{ 
    font-size: 2rem;
    font-weight: 300;
    grid-area: calving-overdue-header; 
}
main.notification-settings > .fever-hypothermia > .fever-hypothermia-header{ 
    grid-area: fever-hypothermia-header;
    font-size: 2rem;
    font-weight: 300;
 }

main.notification-settings > .calving-due > .call{ grid-area: calving-due-call; }
main.notification-settings > .imminent-calving > .call{ grid-area: imminent-calving-call; }
main.notification-settings > .calving-overdue > .call{ grid-area: calving-overdue-call; }
main.notification-settings > .fever-hypothermia > .call{ grid-area: fever-hypothermia-call; }
main.notification-settings > .calving-due > .notifications{ grid-area: calving-due-notifications; }
main.notification-settings > .imminent-calving > .notifications{ grid-area: imminent-calving-notifications; }
main.notification-settings > .calving-overdue > .notifications{ grid-area: calving-overdue-notifications; }
main.notification-settings > .fever-hypothermia > .notifications{ grid-area: fever-hypothermia-notifications; }
main.notification-settings > .calving-due > .email{ grid-area: calving-due-email; }
main.notification-settings > .imminent-calving > .email{ grid-area: imminent-calving-email; }
main.notification-settings > .calving-overdue > .email{ grid-area: calving-overdue-email; }
main.notification-settings > .fever-hypothermia > .email{ grid-area: fever-hypothermia-email; }

main.notification-settings > .vacation-mode > .vacation-mode-explanation{ 
    grid-area: vacation-mode-explanation;
    font-size: 0.9rem;
    font-weight: 300;
 }
main.notification-settings > .calving-due > .calving-due-explanation{ 
    grid-area: calving-due-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .imminent-calving > .imminent-calving-explanation{ 
    grid-area: imminent-calving-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .calving-overdue > .calving-overdue-explanation{ 
    grid-area: calving-overdue-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .fever-hypothermia > .fever-hypothermia-explanation{ 
    grid-area: fever-hypothermia-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left; 
}


main.notification-settings > .vacation-mode > button{
    --vacation: url("../../assets/Sun.svg");
    background-image: var(--vacation);
    background-position: top;
    background-size: 3rem;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 3rem;
    margin: 0;
    width: 4rem;
    height: 4.5rem;
}
main.notification-settings .buttons  > .call{
    --phoneCall: url("../../assets/Phone.svg");
    background-image: var(--phoneCall);
    background-position: top;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    width: max-content;
    height: 3rem;
}
main.notification-settings .buttons  > .notifications {
    --notifications: url("../../assets/Warning.svg");
    background-image: var(--notifications);
    background-position: top;
    background-size: 2.3rem;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    margin-left: 1rem;
    width: max-content;
    height: 3rem;
}
main.notification-settings .buttons  > .email {
    --mails: url("../../assets/Envelope.svg");
    background-image: var(--mails);
    background-position: top;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    margin-left: 1rem;
    width: max-content;
    height: 3rem;
}
main.notification-settings > div > .deactived,
main.notification-settings .buttons > .deactived {
    background-color: var(--dashboard-background);
    border: solid white .08rem;
}
main.notification-settings > div > .pending,
main.notification-settings .buttons > .pending {
    background-color: var(--dashboard-widget-background);
    border: dashed var( --checked-color) .08rem;
}
main.notification-settings > div > .disabled,
main.notification-settings .buttons  > .disabled {
    background-color: var(--dashboard-background);
    border: solid white .08rem;
    opacity: .5;
}

main.notification-settings::-webkit-scrollbar{
    display: none;
}
@media (max-width: 35rem) {
    main.notification-settings h1.header{
        align-self: start;
        margin-top: 0;
    }
    main.notification-settings .close-button{
        margin: 0;
    }
    main.notification-settings{
        z-index: 500;
        top: 0;
        height: 100%;
        border-radius: 0;
        overflow-y: auto;
        grid-row-gap: 1.8rem;
        grid-template-areas:"header close-button"
                            "explanation explanation"
                            "vacation-mode  vacation-mode"
                            "calving-due calving-due"
                            "imminent-calving imminent-calving"
                            "calving-overdue calving-overdue"
                            "fever-hypothermia fever-hypothermia";
        grid-template-columns: auto 2.8rem;
        grid-template-rows: 2.7rem 2.5rem 5.3rem 6rem 6.5rem 7.5rem 6rem;
        transition: none;
    }
    main.notification-settings > .vacation-mode > .vacation-header{
        font-size: 1rem;
        font-weight: 500;
    }
    
    main.notification-settings > .calving-due > .calving-due-header{
        font-size: 1rem;
        font-weight: 500;
     }
    main.notification-settings > .imminent-calving > .imminent-calving-header{
        font-size: 1rem;
        font-weight: 500;
    }
    main.notification-settings > .calving-overdue > .calving-overdue-header{ 
        font-size: 1rem;
        font-weight: 500;
    }
    main.notification-settings > .fever-hypothermia > .fever-hypothermia-header{ 
        font-size: 1rem;
        font-weight: 500;
     }
     main.notification-settings > .vacation-mode{
        grid-template-rows: 2.5rem 1fr 1fr;
     }
    main.notification-settings > .calving-due{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
     }
     main.notification-settings > .imminent-calving{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
        justify-self: start;
     }
     main.notification-settings > .calving-overdue{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
     }
     main.notification-settings > .fever-hypothermia{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
        justify-self: start;
     }
     main.notification-settings::-webkit-scrollbar{
         display: none;
     }
}
@media (min-width: 52rem) {
    main.notification-settings {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
        overflow-y: auto;
    }
}