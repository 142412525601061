main.farm-settings {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1.5rem;
    grid-template-areas:"header close-button"
                       "farm-name-box farm-name-box"
                       "users users";
    grid-template-rows: 4.2rem 3rem 1fr;
    grid-template-columns: auto 4rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5)
}
main.farm-settings .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-bottom: max-content;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.farm-settings button.disabled{
    opacity: .5;
}
main.farm-settings h1.header{
    grid-area: header;
    margin: 0;
    font-size: 2.5rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1rem;
    padding-left: 1rem;
}
main.farm-settings > .farm-name-box {
    grid-area: farm-name-box;
    display: grid;
    grid-template-areas: "farm-name farmName-button";
    grid-template-columns: 1fr auto;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
main.farm-settings > .users > .invite-new-user{
    grid-area: invite-new-user;
    display: grid;
    grid-template-areas: "new-user invite-button";
    grid-template-columns: 1fr auto;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: end;
}

main.farm-settings > .farm-name-box > .input-group.farm-name{
    grid-area: farm-name;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user {
    grid-area: new-user;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}

main.farm-settings > .farm-name-box > .input-group.farm-name input,
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
    background-color: var(--dashboard-background);
    border-radius: .4rem;
    border: solid 1.4px rgb(224, 224, 224, .7);
    font-size: 1.5rem;
    padding-left: 0.5rem;
    color: var(--dashboard-text-color);
    width: 100%;
    height: 2.3rem;
    box-sizing: border-box;
    outline: none;
 }
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
     font-size: 1.1rem;
 }
 main.farm-settings > .farm-name-box > .input-group.farm-name.validate input,
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate input{
    border-radius: .4rem 0 0 0;
}
 main.farm-settings > .farm-name-box > button {
    grid-area: farmName-button;
    padding: 0;
    margin: 0;
    --arrow: url("../../assets/rightArrow.svg");
    background-image: var(--arrow);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}
main.farm-settings > .farm-name-box > button.done,
main.farm-settings > .users > .invite-new-user > button.done {
    background-color: #629F26;
    border-color: var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.pending,
main.farm-settings > .users > .invite-new-user > button.pending {
    background-color: var(--dashboard-widget-background);
    border: dashed 1.4px var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.failed,
main.farm-settings > .users > .invite-new-user > button.failed {
    border: solid 1.4px #d30;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate{
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
}
main.farm-settings > .users{
    grid-area: users;
    display: grid;
    grid-template-areas: "users-header"
                            "user-list"
                            "invite-new-user";
    grid-template-rows: 4rem auto 4rem;
    grid-row-gap: 1rem;
}
main.farm-settings > .users > .users-header{
    grid-area: users-header;
    margin: 0;
    font-size: 3rem;
    font-weight: 200;
    padding-left: 1rem;
}
main.farm-settings > .users > .user-list{
    grid-area: user-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: .7rem;
    font-weight: 300;
    overflow: auto;
    height: 23rem;
}
li.user{
    display: grid;
    position: relative;
    grid-template-areas:
        "name-surname name-surname name-surname"
        "email-phone email-phone email-phone"
        "owner-user owner-user owner-user";
    grid-template-columns: 6rem 7rem 7rem;
    grid-template-rows: 2rem 1.5rem 3rem;
    font-weight: 300;

    padding-left: 4rem;
    background-repeat: no-repeat;
    background-size: 3rem;
    background-color: var(--dashboard-widget-background);
    background-position: left;
    border-bottom: solid 1px var(--dashboard-background);
}
li.user.will-call{
    background-image: url(./../../assets/Phone.svg);
}
li.user .name-surname{
    grid-area: name-surname;
    font-size: 1.5rem;
    width: max-content;
    font-weight: 300;
    pointer-events: none;
}
li.user .email-phone{
    grid-area: email-phone;
    font-size: .9rem;
    font-weight: 300;
    width: max-content;
    opacity: .7;
    pointer-events: none;
}
li.user .showing-owner-user{
    grid-area: owner-user;
    font-size: .8rem;
    width: max-content;
    font-weight: 300;
    opacity: .7;
    pointer-events: none;
}
li.user-options{
    display: flex;
    flex-direction: row;
    grid-column-gap: .5rem;
    font-weight: 300;
    margin-right: 75%;
    height: 5.2rem;
    border-right: solid 3px var(--dashboard-background);
    min-width: 10rem;
}
li.user-options * {
    margin: 0;
    padding: 0;
    margin-top: .4rem;
    padding-top: 2.3rem;
    height: 4.5rem;
    font-weight: 200;
    list-style: none;
    font-size: .9rem;
    background-size: 3rem;
    background-position: top;
    background-repeat: no-repeat;
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    width: 4rem;
}
li.user-options > .removing {
    --remove: url("./Cross.svg");
    background-image: var(--remove);
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
li.user-options > .call-redirect {
    background-image: url("./../../assets/Phone.svg");
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.farm-settings > .users > .invite-new-user > button {
    grid-area: invite-button;
    padding: 0;
    margin: 0;
    --userEmail: url("../../assets/Envelope.svg");
    background-image: var(--userEmail);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}

main.farm-settings > .users > .invite-new-user > button,
main.farm-settings > .farm-name-box > button{
    border-radius: 0 .4rem .4rem 0;
    border-left-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input,
main.farm-settings > .farm-name-box > .input-group.farm-name input{
    border-radius: .4rem 0 0 .4rem;
    border-right-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user span,
main.farm-settings > .farm-name-box > .input-group.farm-name span {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
    opacity: .6;
}
main.farm-settings > .users > .user-list::-webkit-scrollbar {
    display: none;
}
main.farm-settings::-webkit-scrollbar{
    display: none;
}
li.user .user-menu {
    display: block;
    visibility: hidden;
    position: absolute;
    z-index: 10;
    padding: .2rem 0;
    max-width: max-content;
    width: 100%;
    background-color: var(--dashboard-background);
    border: solid 1px var(--dashboard-box-border);
    border-radius: .5rem;
    box-shadow: 0 0 0 0 rgba(0,0,0,.5), 0 0 1rem black;
  }
  li.user .user-menu.active {
    visibility: visible;
  }
  
  li.user .user-menu > .items {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  li.user .user-menu > .items > .item {
    font-size: 1rem;
    padding-left: 2rem;
    padding-right: .5rem;
    background-size: 2rem;
    background-position: left;
    background-repeat: no-repeat;
    margin-bottom: 4px;
}
li.user .user-menu > .items > .removing{
    --remove: url("./Cross.svg");
    background-image: var(--remove);
}
li.user .user-menu > .user-menu-items > #user-call{
    --phoneCall: url("./../../assets/Phone.svg");
      background-image: var(--phoneCall);
}
 li.user .user-menu > .items > .item:last-child {
    margin-bottom: 0;
  }
  li.user .user-menu > .items > .item:hover {
      cursor: pointer;
    background-color: var(--button-action-color);
  }
@media (max-width: 35rem) {
    main.farm-settings h1.header{
        align-self: start;
        font-size: 2rem;
    }
    main.farm-settings .close-button{
        padding-top: 3rem;
    }
    main.farm-settings{
        z-index: 500;
        top: 0;
        height: 100%;
        margin-left: 0;
        border-radius: 0;
        overflow-y: auto;
        grid-row-gap: 2.5rem;
        grid-template-areas:"header close-button"
                            "farm-name-box farm-name-box"
                            "users users";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 3rem 1fr;
        transition: none;
    }
     main.farm-settings::-webkit-scrollbar{
         display: none;
     }
     main.farm-settings > .users{
        grid-template-rows: 4rem auto 1rem;
        grid-row-gap: 0rem;
    }
    main.farm-settings > .users > .users-header{
        font-size: 2rem;
        font-weight: 300;
    }
    main.farm-settings > .users > .user-list{
        height: 25rem;
        max-height: 80%;
        min-width: 100%;
    }
    li.user{
        grid-template-columns: 6rem 6rem auto;
        grid-template-rows: 2rem 1.5rem auto;
        height: 5.5rem;
    }
    li.user * {
        margin-top: .4rem;
    }
}
@media (min-width: 52rem) {
    main.farm-settings {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
        overflow-y: auto;
    }
    main.farm-settings::-webkit-scrollbar{
        display: none;
    }
}