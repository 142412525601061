aside.climate-sens-preview {
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas: "header"
                         "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.climate-sens-preview header {
    padding: 0 .5rem 0 .5rem;
}
@media (max-width: 35rem){ 
    aside.climate-sens-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
}


@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.climate-sens-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: .9em;
        padding-bottom: .3rem;
        min-height: 12rem;
    }
    aside.climate-sens-preview > header {
        width: 8rem;
    }
}


