main.switch-farm {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas:"header close-button"
                            "farms farms";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, .5)
}
main.switch-farm  .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.switch-farm  h1.header{
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.switch-farm  > .farms{
    grid-area: farms;
}
main.switch-farm  > .farms > .farm-list{
    grid-area: farm-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: .7rem;
    font-weight: 300;
    overflow-y: auto;
    height: 38.5rem;
    border-radius: 0;
}
li.farm{
    display: grid;
    grid-template-areas:
        "listItem";
    grid-template-columns: 1fr;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 1.5rem;
    border-right: solid .6rem var(--dashboard-widget-background);
    border-bottom: solid 1px var(--dashboard-background);
}
li.farm a{
    grid-area: listItem;
    display: grid;
    grid-template-areas:
                            "problem-count farm-name-location"
                            "problem-count total-cow"
                            "problem-count last-visit";
    grid-template-columns: 4rem auto;
    grid-template-rows: 1.5rem 1.5rem 3.5rem;
    color: var(--dashboard-text-color);
    text-decoration: none;
    opacity: 1;
}
li.farm.selected{
    border-right: solid .6rem var(--checked-color);
}
li.farm:hover a{
    opacity: 1;
}
li.farm a h3{
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: .8rem;
    font-weight: 300;
}
li.farm a h3 strong{
    font-weight: 400;
    font-size: .9rem;
}
li.farm a .farm-name-location{
    grid-area: farm-name-location;
}
li.farm a .total-cow{
    grid-area: total-cow;
}
li.farm a .last-visit{
    grid-area: last-visit;
}
li.farm a .problem-count{
    grid-area: problem-count;
    background-image: var( --dead-cow);
    background-position: center;
    width: 3rem;
    height: 3rem;
    text-align: center;
    display: inline-block;
    border-radius: .2em;
    align-self: center;
    font-size: 2rem;
    font-weight: 400;
}
li.farm a .problem-count.stable{
    background-image: var( --good-cow);
}
li.farm a .problem-count.error{
    background-image: var( --bad-cow);
}
main.switch-farm > .farms > .farm-list::-webkit-scrollbar {
    display: none;
}
main.switch-farm::-webkit-scrollbar{
    display: none;
}
@media (max-width: 35rem) {
    main.switch-farm  h1.header{
        align-self: start;
    }
    main.switch-farm {
        top: 0;
        height: 100%;
        z-index: 500;
        margin-left: 0;
        border-radius: 0;
        padding: 0;
        overflow-y: auto;
        grid-template-areas:"header close-button"
                            "farms farms";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 1fr;
        transition: none;
    }
    main.switch-farm  > .farms > .farm-list{
        height: 30rem;
        min-height: 99%;
        border-radius: 0;
    }
}
@media (min-width: 52rem) {
    main.switch-farm {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
        overflow-y: auto;
    }
    main.switch-farm  > .farms > .farm-list{
        height: 50.95rem;
        border-radius: 0 0 0 1rem;
    }
}