aside.automation-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas: "header"
                         "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.automation-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas: "title device-id"
                         "switch-group switch-group";
    grid-template-columns: 1fr .5fr;
    grid-template-rows: 4rem auto;
    padding: .1rem;
}
aside.automation-preview > header > h1 {
    grid-area: title;
    font-size: 1.2rem;
}
aside.automation-preview > header > h4.device-id {
    grid-area: device-id;
    font-weight: 300;
    justify-self: end;
}
aside.automation-preview > header > .switch-group {
    grid-area: switch-group;
    height: max-content;
    width: max-content;
}
aside.automation-preview > div.graph {
    grid-area: graph;
}
@media (max-width: 35rem){ 
    aside.automation-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.automation-preview div.graph-preview{
        display: grid;
        grid-template-areas: "graph-header"
                                "graph";

        box-sizing: border-box;
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview header {
        grid-area: graph-header;
        display: grid;
        grid-template-areas: "title device-id";
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.graph-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: end;
        font-weight: 300;
    }
    aside.automation-preview div.graph-preview > div {
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.automation-action {
        display: grid;
        grid-template-areas: "options-header"
                            "switch-box";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 18.7rem;
    }
    aside.automation-preview div.automation-action header {
        grid-area: options-header;
        display: grid;
        grid-template-areas: "title temperature"
                            "device-id temperature";
        grid-template-rows: 3rem auto;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.automation-action > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.automation-action > header > data.temperature {
        grid-area: temperature;
        font-size: 1rem;
        padding: .1rem;
        margin-top: 1em;
        justify-self: end;
        font-weight: 400;
    }
    aside.automation-preview div.automation-action > header > h4 {
        grid-area: temperature;
        font-size: 1rem;
        padding: .1rem;
        justify-self: end;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
    aside.automation-preview div.automation-action > header > data time {
        display: grid;
        align-self: start;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > header > data.temperature::after {
        content: "°C";
        letter-spacing: -.1em;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > div.switch-box {
        grid-area: switch-box;
        background-color: var(--dashboard-background);
        padding-left: .5rem;
    }
}
@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.automation-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: .9em;
        padding-bottom: .3rem;
        height: 12rem;
    }
    aside.automation-preview > header {
        grid-template-areas: "title"
                             "device-id"
                             "switch-group";
        grid-template-rows: 2rem 4rem auto;
        padding: .1rem;
    }
    aside.automation-preview > header > h1 {
        width: 8rem;
    }
    aside.automation-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
}
