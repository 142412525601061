aside.modal-login {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-areas:
        "wicow-logo"
        "login-form-box"
        "version-news";
    grid-template-rows: 9rem max-content auto;
    background-color: var(--dashboard-background);
    z-index: 5;
    overflow: auto;
    width: 100%;
}
aside.modal-login::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: 0.8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
aside.modal-login > span {
    grid-area: wicow-logo;
    --wicow-logo: url("./wiCowLogo.svg");
    background-image: var(--wicow-logo);
    background-repeat: no-repeat;
    background-position: center;
}
aside.modal-login > .login-form-box {
    grid-area: login-form-box;
    background-color: var(--dashboard-widget-background);
    display: grid;
    height: max-content;
    width: 100%;
}
aside.modal-login > .login-form-box > p {
    width: 95%;
    font-weight: 500;
    font-size: 0.95rem;
    padding-left: 0.8rem;
}
aside.modal-login > .login-form-box > p > span {
    font-weight: 700;
}
aside.modal-login > .login-form-box.pending > p {
    opacity: 0.5;
}
aside.modal-login > .login-form-box.retry > p {
    margin-top: 0.5rem;
}
aside.modal-login > .login-form-box > p > a.mail {
    font-weight: 700;
    color: var(--dashboard-text-color);
}
aside.modal-login .version-news {
    grid-area: version-news;
    display: grid;
    grid-template-areas:
        "news-header"
        "news-list";
    grid-template-rows: min-content auto;
    align-content: center;
}
aside.modal-login .version-news .news-header {
    grid-area: news-header;
    padding-left: 2rem;
    font-weight: 300;
}
aside.modal-login .version-news .news-list {
    grid-area: news-list;
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 1rem;
}
aside.modal-login .version-news .news-list li {
    margin-bottom: 0.5rem;
}

aside.modal-login::-webkit-scrollbar {
    display: none;
}

aside.modal-login .version-news .news-list .demo-link {
    color: var(--button-action-color);
}

aside.modal-login .version-news .news-list .demo-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 35rem) {
    aside.modal-login {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    aside.modal-login > .wicow-tip {
        display: none;
    }
}
@media (min-width: 29rem) {
    aside.modal-login {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        justify-self: center;
        grid-template-areas:
            "wicow-logo wicow-tip"
            "login-form-box wicow-tip"
            "version-news wicow-tip";
        grid-template-columns: 24rem auto;
        grid-template-rows: 9rem max-content 31.5rem;
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
        margin-top: 1.5rem;
        justify-content: center;
    }
    aside.modal-login::after {
        position: fixed;
        background-color: var(--dashboard-background);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        content: "\00a0";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    aside.modal-login > .version-news {
        background-color: var(--dashboard-widget-background);
        align-content: start;
        padding-top: 2rem;
        min-height: 25rem;
    }
    aside.modal-login > .wicow-tip {
        grid-area: wicow-tip;
        display: grid;
        grid-template-areas:
            "wicow-tip-image"
            "wicow-tip-header"
            "wicow-tip-explanation";
        grid-template-rows: auto auto auto;
        background-color: var(--dashboard-widget-background);
        align-content: center;
        justify-content: center;
        height: 100%;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-image {
        grid-area: wicow-tip-image;
        margin-block-start: 0;
        margin-block-end: 0;
        --tip3-image: url("./wiCowTip3Image.svg");
        background: var(--tip3-image);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 25rem;
        height: 15rem;
        width: 25rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 3rem;
        justify-self: center;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-header {
        grid-area: wicow-tip-header;
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 1.7rem;
        font-weight: 300;
        padding-left: 4rem;
        justify-self: start;
        align-self: end;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-explanation {
        grid-area: wicow-tip-explanation;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 1rem;
        width: 20.5rem;
        justify-self: center;
    }
    aside.modal-login > div.wicow-feature > p.wicow-feature-explanation > a {
        color: var(--button-action-color);
        font-weight: 400;
        cursor: pointer;
        text-decoration: none;
    }
}
