article.climate-sens {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header"
                         "graph";
    grid-template-rows: 2rem auto;

    align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;

    --climate-sens: url(./barn.svg);
    background-image: var(--climate-sens), linear-gradient(180deg, #629F26 0%, #146C3C 100%);


    background-blend-mode: overlay, normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;
    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}

article.climate-sens.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}

article.climate-sens.preview:not(.active) {
    opacity: .4;
}


@media(min-width: 52rem) {
    article.climate-sens {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.climate-sens > h5 { font-size: 1em; }
    article.automatclimate-sension .image {
        background-position: center 1.5rem;
        background-size: 3.6rem;
    }
}
