/* .filter-box{
    display: grid;
    grid-template-areas: "query icon";
    grid-template-columns: 1fr 2rem;
    grid-auto-rows: minmax(2rem, auto);
    background-color: var(--dashboard-background);
    border: solid 2px #E0E0E0;
    padding: .4rem .5rem;
    color: white;
    margin: 0;
    border-radius: .4rem;
} */
.filter-box{
    display: flex;
    flex-wrap: wrap;
    background-color: var(--dashboard-background);
    border: solid 2px #E0E0E0;
    padding: .4rem .5rem;
    color: white;
    margin: 0;
    border-radius: .4rem;
    cursor: text;
    /* justify-content: space-between; */
}

.filter-box.open{
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.filter-box div.query {
    width: max-content;
    margin: .1rem;
}
.filter-box i.filter-icon {
    background: url(./Filter.svg) no-repeat;
    background-size: 2rem;
    background-position: center;
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: auto;
    order: 2;
    cursor: pointer;
    align-self: center;
}
.filter-box.pending i.filter-icon {
    border-radius: 50%;
    background-size: 0;
    border-style: dashed;
    border-color: #379;
    border-width: 3px;
    width: 1.4rem;
    height: 1.4rem;
    animation: rotation linear 2s infinite;
}
.filter-box i.filter-icon.tag-selected {
    grid-area: icon;
    background: url(./Done.svg) no-repeat;
    background-size: 1.6rem;
    background-position: center;
    width: 1.8rem;
    height: 1.8rem;
    justify-self: end;
}
.filter-box i.filter-icon.selected {
    background-blend-mode: normal;
}

.filter-box input,
.filter-results input{
    /* display: block; */
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
    color: white;
    height: 100%;
    align-self: center;
    font-size: 1rem;
}

.filter-box input.tag-param-input {
    display: inline-block;
    width: 1rem;
    cursor: pointer;
    text-align: center;
}

.filter-box input.tag-param-input.digits-1{
    width: 1rem;
}
.filter-box input.tag-param-input.digits-2{
    width: 1.2rem;
}
.filter-box input.tag-param-input.digits-3{
    width: 2rem;
}

.filter-results input.tag-param-input{
    display: inline-block;
    width: 1rem;
    opacity: .5;
}
.filter-results input.tag-param-input.digits-1{
    width: 1rem;
}
.filter-results input.tag-param-input.digits-2,
.filter-results input.tag-param-input.digits-3{
    width: 2rem;
}

.filter-box div.query.new input {
    display: inline-block;
    margin-top: .3rem;
}

tag,
sort{
    display: inline-block;
    background-color: var(--tag-color);
    align-self: center;
    border-radius: .3rem;
    text-align: center;
    /* margin: .2rem .3rem .3rem .2rem; */
    padding: 0 .3rem;
    padding-bottom: .2rem;
}
sort{
   cursor: pointer;
   background-color: var(--tag-sort-color);
}

parameter {
    background-color: var(--tag-parameter-color);
    padding: 0 .3rem;
    padding-bottom: .2rem;
}
parameter span.following {
    opacity: .4;
}

logic {
    background-color: var(--tag-logic-color);
    padding: 0 .3rem;
    padding-bottom: .2rem;
}

.filter-box tag .remove::before,
.filter-box sort .remove::before {
    content: "\00d7";
}

.filter-box tag.ascending:before,
.filter-box sort.ascending:before {
    content: "\25b2 ";
    font-size: .8em;
    opacity: .4;
}

.filter-box tag.descending:before,
.filter-box sort.descending:before {
    content: "\25bc ";
    font-size: .8em;
    opacity: .4;
}

.filter-results sort:before {
    content: "\25b2\25bc ";
    font-size: .6em;
    opacity: .4;
    vertical-align: middle;
}

.filter-box tag span.remove,
.filter-box sort span.remove{
    content: "\00d7";
    margin: 0 0 0 .3rem;
    opacity: .4;
    cursor: pointer;
}
.filter-box tag span.remove:hover,
.filter-box sort span.remove:hover{
    opacity: .8;
}

ul.filter-results,
div.filter-options {
    position: absolute;
    background: var(--dashboard-background);
    margin: 0;
    padding: 0;
    border: solid 2px #BBB;
    border-radius: 0 0 .25rem .25rem;
    border-top: none;
    list-style: none;
    z-index: 1000;
    width: 99%;
    width: 26.8rem;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
    right: 0;
    left: 0;
    width: calc(100% - 2.25rem);
    margin-left: auto !important;
    margin-right: auto !important;
    top: -.8rem;
}

ul.filter-results li {
    padding: .25rem .5rem;
    list-style: none;

}

ul.filter-results li.hover {
    background-color: black;
}
ul.filter-results li p {
    margin: 0;
}

ul.filter-results li tag {
    padding: 0 .6rem 0 .6rem;
    padding-bottom: .2rem;
}
ul.filter-results li logic {
    border-radius: .3rem;
}
ul.filter-results li parameter {
    border-radius: .3rem;
}

.filter-box div.query *:first-child,
.filter-box div.query.new *:first-child {
    border-radius: .3rem 0 0 .3rem;
}
.filter-box div.query *:last-child,
.filter-box div.query.new *:not(input):last-child {
    border-radius: 0 .3rem .3rem 0;
}

.filter-box div.query.single-element *{
    border-radius: .3rem;
}
.filter-options button:disabled{
    opacity: .5;
}

@media (max-width: 35rem) {
    ul.filter-results,
    div.filter-options {
        right: 0;
        left: 0;
        width: calc(100% - 2.25rem);
        margin-left: auto !important;
        margin-right: auto !important;
        top: -.8rem;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
