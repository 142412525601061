svg#icons div.badge-item {
    --gradient: var(--dead-cow);
    --badge-size: 2rem;
    background-image: var(--icon), var(--gradient);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; 
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    border: solid calc(var(--badge-size)/2*0.15) var(--dashboard-text-color);
    box-sizing: border-box;
}

svg#icons div.badge-item.Tsens {
    --icon: url(./../../../assets/Tsens.svg);
}

svg#icons div.badge-item.earTag {
    --icon: url(./../../../assets/EarTag.svg);
}

svg#icons div.badge-item.cow {
    --icon: url(./../../../assets/CowPictogram.svg);
}


svg#icons div.badge-item.estrous {
    background-size: 2.2rem;
    --icon: url(./../../../assets/estrous.svg);
}
svg#icons div.badge-item.temperature {
    --icon: url(./../../../assets/temperatureIcon.svg);
}
svg#icons div.badge-item.heatOver {
    background-size: 2.2rem;
    --icon: url(./../../../assets/heatOver.svg);
}


svg#icons div.badge-item.Tsens.idle,
svg#icons div.badge-item.Tsens.assigned,
svg#icons div.badge-item.Tsens.pending,
svg#icons div.badge-item.Tsens.network-problem { --gradient: var(--idle-sensor) }
svg#icons div.badge-item.Tsens.active,
svg#icons div.badge-item.Tsens.stable { --gradient: var(--stable-sensor) }
svg#icons div.badge-item.Tsens.unstable,
svg#icons div.badge-item.Tsens.low-battery { --gradient: var(--unstable-sensor) }
svg#icons div.badge-item.Tsens.fault { --gradient: var(--bad-sensor) }

svg#icons div.badge-item.earTag.idle,
svg#icons div.badge-item.earTag.assigned,
svg#icons div.badge-item.earTag.pending,
svg#icons div.badge-item.earTag.network-problem { --gradient: var(--idle-sensor) }
svg#icons div.badge-item.earTag.active,
svg#icons div.badge-item.earTag.stable { --gradient: var(--stable-sensor) }
svg#icons div.badge-item.earTag.unstable,
svg#icons div.badge-item.earTag.low-battery { --gradient: var(--unstable-sensor) }
svg#icons div.badge-item.earTag.fault { --gradient: var(--bad-sensor) }

svg#icons div.badge-item.cow.pregnant { --gradient: var(--good-cow) }
svg#icons div.badge-item.cow.due,
svg#icons div.badge-item.cow.overdue { --gradient: var(--bad-cow-handled) }
svg#icons div.badge-item.cow.calving,
svg#icons div.badge-item.estrous.heat { --gradient: var(--bad-cow) }

svg#icons div.badge-item.temperature.fever,
svg#icons div.badge-item.temperature.hypothermia { --gradient: var(--bad-cow) }