main.dashboard-settings {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
    grid-template-areas:    "header close-button"
                            "shortcut-buttons shortcut-buttons"
                            "auto-sort-buttons auto-sort-buttons";
    grid-template-columns: auto 7rem;
    grid-template-rows: 4rem 10rem 6rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5)
}
main.dashboard-settings   .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-left: 4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
main.dashboard-settings   h1.header{
    grid-area: header;
    margin: 0;
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.dashboard-settings  > .shortcut-buttons{
    grid-area: shortcut-buttons;
    display: grid;
    grid-template-areas:"shortcut-header"
                       "buttons1"
                       "shortcut-button-explanation";
    grid-template-rows: 4rem auto 2rem;
}
main.dashboard-settings  > .shortcut-buttons > .shortcut-header{
    grid-area: shortcut-header;
    font-weight: 400;
}
main.dashboard-settings  > .shortcut-buttons > .buttons{
    grid-area: buttons1;
    display: grid;
    grid-template-areas:"cowList switchFarm notifications";
    grid-template-columns: 5rem 6rem auto;
    border: solid 3px var(--dashboard-background);
    border-radius: .4rem .4rem .4rem .4rem;
    grid-column-gap: 1rem;
    margin: auto;
    margin-left: 0;
    padding: .15rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button{
    background-color: var(--dashboard-background);
    border: solid white .05rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button.selected{
    background-color: var(--button-action-color);
    border: none;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button.disabled{
    background-color: var(--dashboard-widget-background);
    opacity: .5;
    cursor: auto;
    border: solid var(--dashboard-background) .05rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list{
    grid-area: cowList;
    --cowList: url("./CowList.svg");
    background-image: var(--cowList);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 5.5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm{
    grid-area: switchFarm;
    --switch: url("./SwitchFarm.svg");
    background-image: var(--switch);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6.5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications{
    grid-area: notifications;
    --notifications: url("../../assets/Warning.svg");
    background-image: var(--notifications);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 9rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

main.dashboard-settings  > .shortcut-buttons > .shortcut-button-explanation{
    grid-area: shortcut-button-explanation;
    font-weight: 300;
}
main.dashboard-settings  > .auto-sort-buttons{
    grid-area: auto-sort-buttons;
    display: grid;
    grid-template-areas:"auto-sort-header"
                       "buttons2"
                       "auto-sorting-explanation"
                       "style-explanation";
    grid-template-rows: 4rem auto auto 1.7rem;
}

main.dashboard-settings  > .auto-sort-buttons > .buttons{
    grid-area: buttons2;
    display: grid;
    grid-template-areas:"tracking priority farmNumber events";
    grid-template-columns: 5rem 4.5rem 6rem auto;
    grid-column-gap: 1rem;
    border-radius: .4rem .4rem .4rem .4rem;
    border: solid 3px var(--dashboard-background);
    margin: auto;
    margin-left: 0;
    padding: .15rem;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button{
    background-color: var(--dashboard-background);
    border: solid white .05rem;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button.selected{
    background-color: var(--button-action-color);
    border: none;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button.disabled{
    background-color: var(--dashboard-widget-background);
    border: solid 1px var(--dashboard-background);
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking{
    grid-area: tracking;
    --tracking: url("./Tracking.svg");
    background-image: var(--tracking);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    font-size: .9rem;
    width: 5.5rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority{
    grid-area: priority;
    --priority: url("../../assets/Warning.svg");
    background-image: var(--priority);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number{
    grid-area: farmNumber;
    --farmNumber: url("./FarmNumber.svg");
    background-image: var(--farmNumber);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6.5rem;
    font-size: .8rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
    grid-area: events;
    --events: url("./Events.svg");
    background-image: var(--events);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

main.dashboard-settings  > .auto-sort-buttons > .auto-sort-header{
    grid-area: auto-sort-header;
    font-weight: 400;
}
main.dashboard-settings  > .auto-sort-buttons > .auto-sorting-explanation{
    grid-area: auto-sorting-explanation;
}
main.dashboard-settings  > .auto-sort-buttons > .style-explanation{
    grid-area: style-explanation;
}
main.dashboard-settings  > .auto-sort-buttons > label{
    font-weight: 300;
}
main.dashboard-settings::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.dashboard-settings h1.header{
        align-self: start;
        margin-top: 0;
        font-size: 2rem;
    }
    main.dashboard-settings .close-button{
        margin: 0;
    }
    main.dashboard-settings {
        top: 0;
        height: 100%;
        z-index: 500;
        margin-left: 0;
        border-radius: 0;
        overflow-y: auto;
        grid-row-gap: 2rem;
        grid-template-areas:    "header close-button"
                                "shortcut-buttons shortcut-buttons"
                                "auto-sort-buttons auto-sort-buttons";
        grid-template-columns: auto 3rem;
        grid-template-rows: 2.7rem 10rem 6rem;
        transition: none;
    }
    main.dashboard-settings  > .shortcut-buttons{
        grid-template-rows: 4rem auto auto;
    }
    main.dashboard-settings  > .auto-sort-buttons{
        grid-template-rows: 4rem auto auto;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons{
        grid-template-columns: 4.3rem 5.3rem auto;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons{
        grid-template-columns: 3.8rem 3.3rem 5.8rem auto;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking{
        background-size: 3rem;
        width: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority{
        background-size: 3rem;
        width: 4rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number{
        background-size: 3rem;
        width: 6.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
        background-size: 3rem;
        width: 5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list{
        background-size: 3rem;
        width: 5rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm{
        background-size: 3rem;
        width: 6rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications{
        background-size: 3rem;
        width: 8rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
}
@media (min-width: 52rem) {
    main.dashboard-settings {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
        overflow-y: auto;
    }
}