aside.cow-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}

aside.cow-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas:
        "title title identity"
        "title title breed-birthdate"
        "tags sessions .";
    grid-template-columns: 0.6fr 1fr 0.5fr;
    grid-template-rows: 1.4fr 2fr 3fr;
    padding: 0.1rem;
}

aside.cow-preview > div.body {
    grid-area: graph;
    /* display: grid;
    grid-template-areas: "picker" "chart";
    grid-template-rows: 2rem auto; */
}

aside.cow-preview > header > h1,
aside.cow-preview > header h4 {
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
}
aside.cow-preview > header > h4 {
    font-size: 0.8em;
    place-self: start end;
}

aside.cow-preview > header > h1 {
    grid-area: title;
    font-size: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
aside.cow-preview header > h1 > strong {
    margin-right: 0.5rem;
}
aside.cow-preview > header > h4.identity-number {
    grid-area: identity;
}

aside.cow-preview > header > div.breed-birthdate {
    grid-area: breed-birthdate;
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    justify-self: end;
}
aside.cow-preview > header > .sessions {
    grid-area: sessions;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
aside.cow-preview > header > div.sessions h4 {
    font-size: 0.8em;
    margin: 0;
}
aside.cow-preview .pickers {
    grid-area: picker;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
aside.cow-preview .pickers > div {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
}
aside.cow-preview .pickers .graph-picker {
    background-color: #151515;
    background-blend-mode: multiply;
    border-radius: 12px;
    width: auto;
    height: min-content;
    padding: 0 1em 0 1em;
    line-height: 1.8rem;
    cursor: pointer;
}
aside.cow-preview .pickers .graph-picker.disabled {
    opacity: 0.5;
    pointer-events: none;
}
aside.cow-preview div.sessions .session:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview div.sessions .session.stable:before {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
aside.cow-preview div.sessions .session.unstable:before {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

aside.cow-preview > header > div.sessions h4 strong {
    cursor: pointer;
    text-decoration: underline;
}
aside.cow-preview > header > .tags {
    grid-area: tags;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
aside.cow-preview > header > .tags span {
    font-weight: 300;
    margin-left: 0.3em;
}
aside.cow-preview header > .tags li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview > header > .tags li {
    display: inline-block;
}

aside.cow-preview header > .tags > li.tracked:before,
aside.cow-preview header > .tags > li.pregnant:before,
aside.cow-preview header > .tags > li.post-partum:before,
aside.cow-preview header > .tags > li.estrous:before,
aside.cow-preview header > .tags > li.inseminated:before {
    background-image: var(--good-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

aside.cow-preview header > .tags > li.calving:before {
    background-image: var(--calving-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

aside.cow-preview header > .tags > li.hypothermia:before,
aside.cow-preview header > .tags > li.fever:before,
aside.cow-preview header > .tags > li.heat:before {
    background-image: var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
aside.cow-preview header > .tags > li.due:before {
    background-image: (--due-cow);
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}
aside.cow-preview header > .tags > li.overdue:before {
    background-image: var(--bad-cow-handled);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
}
.cow-menu {
    position: absolute;
    display: grid;
    z-index: 100;
    grid-template-areas: "indicator items";
    grid-template-columns: 1rem auto;
    max-width: 10rem;
    width: 100%;
}
.cow-menu.left {
    grid-template-areas: "items indicator";
    grid-template-columns: auto 1rem;
}
.cow-menu.up {
    grid-template-areas: "items" "indicator";
    grid-template-rows: auto 1rem;
    grid-template-columns: 1fr;
}
.cow-menu .indicator {
    grid-area: indicator;
    display: grid;
}
.cow-menu .indicator div {
    height: 100%;
}
.cow-menu.up .indicator {
    width: 100%;
    height: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
}
.cow-menu.up .indicator div {
    height: 1rem;
}
.cow-menu .indicator div.start path,
.cow-menu .indicator div.end path {
    stroke: aliceblue;
    stroke-width: 0.14rem;
}
.cow-menu .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: 0.05rem;
    fill-opacity: 0.8;
}
.cow-menu > .items {
    grid-area: items;
    background-color: rgb(0, 0, 0, 0.7);
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.cow-menu.up > .items {
    flex-direction: column;
    align-items: initial;
}
.cow-menu > ul > .item {
    font-size: 1rem;
    width: 100%;
    padding-left: 2.3rem;
    background-size: 2rem;
    background-position: left;
    background-position-x: 0.2rem;
    background-repeat: no-repeat;
}
.cow-menu.up > ul > .item {
    width: auto;
}
.cow-menu > .items .edit {
    --edit: url("../../assets/Edit.svg");
    background-image: var(--edit);
}
.cow-menu > .items .Tsens {
    --tsens: url("../../assets/Tsens.svg");
    background-image: var(--tsens);
}
.cow-menu > .items .earTag {
    --eartag: url("../../assets/EarTag.svg");
    background-image: var(--eartag);
}
.cow-menu > ul > .item:hover {
    cursor: pointer;
    background-color: var(--button-action-color);
}
@media (max-width: 35rem) {
    aside.cow-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.cow-preview div.graph {
        display: grid;
        grid-template-areas:
            "header"
            "graph";
        box-sizing: border-box;
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.event-group {
        display: grid;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
        box-sizing: border-box;
    }
    aside.cow-preview div.event-group div.cow-events {
        align-self: start;
    }
    aside.cow-preview div.cow-options {
        display: grid;
        grid-template-areas:
            "options-header"
            "buttons";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 18.7rem;
    }

    aside.cow-preview div.graph > div {
        grid-area: graph;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.graph header {
        grid-area: header;
        display: grid;
        grid-template-areas:
            "title identity"
            "title breed-birthdate"
            "tags sessions"
            "tags sessions"
            "picker picker";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.4rem 1.4rem 3rem 2rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    /* aside.cow-preview header div.pickers {
        grid-area: picker;
    } */
    aside.cow-preview div.graph > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    aside.cow-preview div.graph > header > h4.identity-number {
        grid-area: identity;
        margin: 0.6rem 0.4rem 0 0;
        justify-self: end;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate {
        grid-area: breed-birthdate;
        height: 1rem;
        display: flex;
        flex-direction: row;
        font-size: 0.8em;
        justify-self: end;
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview div.graph > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
    }
    aside.cow-preview div.graph > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }

    aside.cow-preview div.graph > header > h1,
    aside.cow-preview div.graph > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.graph > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.graph > header > div.sessions h4 {
        font-size: 0.8em;
        margin: 0;
    }
    aside.cow-preview div.graph > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    aside.cow-preview div.graph > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.graph > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.graph > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.graph > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(./../../assets/fullScreen.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
    aside.cow-preview div > header > div.sessions h4 strong {
        cursor: pointer;
        text-decoration: underline;
    }
    aside.cow-preview div.graph > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.hypothermia:before,
    aside.cow-preview div.graph > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.tracked:before,
    aside.cow-preview div.graph > header > .tags > li.pregnant:before,
    aside.cow-preview div.graph > header > .tags > li.estrous:before,
    aside.cow-preview div.graph > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title ."
            "title ."
            "tags sessions"
            "tags sessions";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.5rem 1.4rem 3rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options div {
        grid-area: buttons;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    aside.cow-preview div.cow-options > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .sessions .session {
        margin-top: 0.3rem;
    }

    aside.cow-preview div.cow-options > header > h1,
    aside.cow-preview div.cow-options > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.cow-options > header > div.sessions h4 {
        font-size: 0.8em;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.cow-options > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.cow-options > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.cow-options > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.cow-options > header > .tags > li.hypothermia:before,
    aside.cow-preview div.cow-options > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.tracked:before,
    aside.cow-preview div.cow-options > header > .tags > li.pregnant:before,
    aside.cow-preview div.cow-options > header > .tags > li.estrous:before,
    aside.cow-preview div.cow-options > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options div button {
        background-repeat: no-repeat;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        background-position: top;
        background-color: var(--dashboard-background);
        background-size: 2.3rem;
        border: solid 1px var(--dashboard-box-border);
        height: 3.5rem;
        font-size: 0.9rem;
        font-weight: 200;
        padding-top: 2rem;
    }
    aside.cow-preview div.cow-options div .edit {
        --edit: url("../../assets/Edit.svg");
        background-image: var(--edit);
        min-width: 4rem;
    }
    aside.cow-preview div.cow-options div .earTag {
        --note: url("../../assets/EarTag.svg");
        background-image: var(--note);
    }
    aside.cow-preview div.cow-options div .Tsens {
        --note: url("../../assets/Tsens.svg");
        background-image: var(--note);
    }

    aside.cow-preview::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 29rem) and (max-width: 40rem) {
    aside.cow-preview {
        height: 16rem;
        z-index: 1;
    }
    aside.cow-preview > div {
        border-left: none;
    }
    aside.cow-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "title identity"
            "tags breed-birthdate"
            "tags sessions";
        grid-template-columns: 14rem 1fr;
        grid-template-rows: 1.4rem 1.4rem 1.3rem;
    }
    aside.cow-preview > header > h4.identity-number {
        margin: 0.6rem 0.4rem 0 0;
        justify-self: end;
    }
    aside.cow-preview > header > div.breed-birthdate {
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview > header > .sessions {
        flex-direction: column;
        justify-self: end;
        margin-right: 0.4rem;
    }
    aside.cow-preview > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }

    aside.cow-preview > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(./../../assets/fullScreen.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
}

@media (min-width: 40rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.cow-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
    }

    aside.cow-preview > header {
        grid-template-areas:
            "title title"
            "identity identity"
            "breed-birthdate ."
            "tags tags"
            "sessions sessions";
        grid-template-columns: max-content auto;
        grid-template-rows: 2.2rem 1rem 2rem 5rem auto;
    }
    aside.cow-preview > header h4,
    aside.cow-preview > header div.breed-birthdate {
        justify-self: start;
    }
    aside.cow-preview > header > .tags {
        justify-self: left center;
    }
    aside.cow-preview > header > h1 {
        width: 10rem;
    }
}

aside.cow-preview > header > h4.session > button {
    background-color: var(--dashboard-widget-background);
    color: var(--dashboard-background);
    border: none;
    margin: 0 0.5em;
    border-radius: 0.25em;
    outline: none;
}

aside.cow-preview > header > h4.session > button:hover {
    background-color: var(--dashboard-text-color);
    color: var(--dashboard-background);
}

/* Full Screen */
aside.cow-preview.fullscreen,
aside.cow-preview:fullscreen {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100%;
    height: 100vh;
}
aside.cow-preview.fullscreen > header,
aside.cow-preview:fullscreen > header {
    grid-template-areas:
        "title title fullscreen"
        "identity . ."
        "breed-birthdate . ."
        "tags sessions .";
    grid-template-columns: 0.3fr 1fr 0.2fr;
    grid-template-rows: 2.8rem 1.4rem 1.4rem 1.4rem;
}
aside.cow-preview.fullscreen > header > h4,
aside.cow-preview:fullscreen > header > h4 {
    justify-self: start;
}
aside.cow-preview.fullscreen > header > h4 {
    justify-self: end;
}
aside.cow-preview.fullscreen > header > h4.identity-number {
    justify-self: start;
}
aside.cow-preview.fullscreen > header > h4.sessions {
    justify-self: start;
}

aside.cow-preview.fullscreen > header > a.fullscreen-button,
aside.cow-preview:fullscreen > header > a.fullscreen-button {
    grid-area: fullscreen;
    background-image: url(./../../assets/normalScreen.svg);
    background-repeat: no-repeat;
    border: none;
    height: 1.8rem;
    width: 1.8rem;
    margin-top: 0.5rem;
    justify-self: end;
}

/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 35rem) {
        aside.cow-preview div.graph {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
        aside.cow-preview div.event-group {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
    }
    @media {
        aside.cow-preview.fullscreen {
            width: 100vw;
        }
        aside.cow-preview.fullscreen div .data-graph {
            margin-top: 5.4rem;
            height: 12rem;
        }
        aside.cow-preview > header > a.fullscreen-button {
            background-image: url(./../../assets/deviceRotate.svg);
            background-size: 2rem;
            height: 3rem;
            width: 1.8rem;
            margin-right: 0.2rem;

            transform-origin: 50% 50%;
            -webkit-animation: spin 1.4s linear infinite;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-timing-function: ease-in-out;

            -moz-animation: spin 1.4s linear infinite;
            -moz-animation-iteration-count: 1;
            -moz-animation-timing-function: ease-in-out;

            animation: spin 1.4s linear infinite;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
        }
        aside.cow-preview.fullscreen > header > a.fullscreen-button {
            background-image: url(./../../assets/deviceRotate.svg);
            background-size: 3rem;
            height: 3rem;
            width: 3rem;
            transform: rotate(90deg) scaleX(-1);
            margin-top: 0;
        }
        @-moz-keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(-90deg);
            }
        }
        @-webkit-keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(-90deg);
            }
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(-90deg);
            }
        }
    }
}
