main.your-profile {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    grid-template-areas:
        "header close-button"
        "language language"
        "name-surname name-surname"
        "email-address email-address"
        "number-input number-input"
        "call-contract call-contract"
        "warning-new-user warning-new-user"
        "update update";
    grid-template-columns: auto 3rem;
    grid-template-rows: 5rem 5rem 5rem 5rem 1fr 1fr 1fr 1fr;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.your-profile .close-button {
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
main.your-profile h1.header {
    grid-area: header;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.your-profile > label.call-contract {
    grid-area: call-contract;
    justify-self: end;
    font-size: 0.9em;
    font-style: italic;
}
main.your-profile > label.warning-new-user {
    grid-area: warning-new-user;
    justify-self: center;
    font-size: 0.9em;
    font-style: italic;
}
main.your-profile > label.call-contract strong {
    font-weight: bold;
}
main.your-profile > button.update {
    grid-area: update;
    align-self: end;
}
main.your-profile > button.update:disabled {
    background-color: var(--dashboard-background);
    border: solid var(--button-action-color) 0.1rem;
}
main.your-profile > .name-surname {
    grid-area: name-surname;
    display: grid;
    grid-template-areas: "first-name last-name";
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.1rem;
}
main.your-profile > .language {
    grid-area: language;
    display: grid;
    grid-template-areas:
        "languageEn languageDe languageTr languageIt languageDa languagePt languageEs languageBg languageRu languageFr"
        "lang lang lang lang lang lang lang lang lang lang";
    grid-template-columns: 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 2.8rem;
    grid-template-rows: 1fr 2fr;
    height: 2.6rem;
    width: min-content;
    border: solid 3px var(--dashboard-background);
    border-radius: 0.4rem;
}
main.your-profile > .input-group.email-address {
    grid-area: email-address;
}
main.your-profile > .number-input {
    grid-area: number-input;
    display: grid;
    grid-template-areas: "phone-number";
    grid-template-columns: 1fr;
}

main.your-profile > .name-surname > .input-group.first-name {
    grid-area: first-name;
}
main.your-profile > .name-surname > .input-group.last-name {
    grid-area: last-name;
}
main.your-profile > .number-input > .input-group.phone-number {
    grid-area: phone-number;
}

main.your-profile > .language > button {
    font-size: 0.9rem;
    font-weight: 250;
    padding: 0;
    margin: 0;
    margin-top: 0.1rem;
    margin-left: 0.2rem;
    background-color: var(--dashboard-background);
    width: 2.5rem;
    height: 2.4rem;
}
main.your-profile > .language > button.selected {
    border: solid white 0.04rem;
}
main.your-profile > .language > button:active {
    opacity: 0.5;
}
main.your-profile > .language > .lang {
    grid-area: lang;
    font-size: 0.9rem;
    font-weight: 300;
}
main.your-profile > .name-surname .input-group,
main.your-profile > .input-group.email-address,
main.your-profile > .number-input .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: 2.6rem 1rem;
}
main.your-profile > .name-surname > .input-group.first-name input {
    text-align: right;
    padding-right: 0.5rem;
}
main.your-profile > .name-surname > .input-group > input,
main.your-profile > .input-group.email-address > input,
main.your-profile > .number-input > .input-group > input {
    background-color: var(--dashboard-background);
    border-radius: 0.4rem;
    border: solid 1.4px rgb(224, 224, 224, 0.7);
    font-size: 1rem;
    padding-left: 0.5rem;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
    outline: none;
}
main.your-profile > .name-surname > .input-group span,
main.your-profile > .input-group.email-address span,
main.your-profile > .number-input > .input-group span {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
}
main.your-profile > .name-surname > .input-group.first-name span {
    text-align: right;
    padding-right: 0.3rem;
}
main.your-profile > .name-surname > .input-group.last-name span {
    padding-left: 0.3rem;
}
/* left */
main.your-profile > .name-surname > .input-group.last-name input,
main.your-profile > .number-input > button {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}
main.your-profile > .name-surname > .input-group.last-name.validate input {
    border-radius: 0 0.4rem 0 0;
}
/* right */
main.your-profile > .name-surname > .input-group.first-name input {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}
main.your-profile > .name-surname > .input-group.first-name.validate input,
main.your-profile > .number-input > .input-group.phone-number.validate input {
    border-radius: 0.4rem 0 0 0;
}
/* left and right */
main.your-profile > .input-group.email-address.validate input {
    border-radius: 0.4rem 0.4rem 0 0;
}
@media (max-width: 35rem) {
    main.your-profile h1.header {
        align-self: start;
        font-size: 2rem;
    }
    main.your-profile .close-button {
        margin: 0;
    }
    main.your-profile {
        top: 0;
        height: 100%;
        z-index: 500;
        margin-left: 0;
        border-radius: 0;
        grid-template-areas:
            "header close-button"
            "language language"
            "name-surname name-surname"
            "email-address email-address"
            "number-input number-input"
            "call-contract call-contract"
            "warning-new-user warning-new-user"
            "update update";
        grid-template-columns: auto 3rem;
        grid-template-rows: 3rem 7rem 5rem 5rem 5rem 0.4rem 0.4rem 1fr;
        transition: none;
    }
    main.your-profile > label.warning-new-user {
        font-size: 0.8em;
    }
    main.your-profile > button {
        height: 2.3rem;
    }
    main.your-profile > .language {
        grid-template-areas:
            "languageEn languageDe languageTr languageIt languageDa languagePt"
            "languageEs languageBg languageRu languageFr . ."
            "lang lang lang lang lang lang";
        grid-template-columns: 3rem 3rem 3rem 3rem 3rem 2.8rem;
        grid-template-rows: 1fr 1fr;
        height: 5.2rem;
        width: min-content;
        margin-top: 1.5rem;
        margin-left: 0;
        margin-bottom: 1.5rem;
    }
    main.your-profile > .language > .lang {
        justify-self: left;
        padding-top: 0.5rem;
    }
    main.your-profile > .number-input {
        grid-template-rows: 4.6rem 1rem;
    }
    main.your-profile > .name-surname .input-group,
    main.your-profile > .input-group.email-address,
    main.your-profile > .number-input .input-group {
        height: 4.4rem;
        margin-top: 2rem;
    }
    main.your-profile > .number-input > button {
        margin-top: 2rem;
    }
}
@media (min-width: 52rem) {
    main.your-profile {
        width: 40rem;
        top: -0.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
        overflow-y: auto;
    }
    main.your-profile::-webkit-scrollbar {
        display: none;
    }
}
