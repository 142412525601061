ol.events {
    list-style: none;
    padding: 0;
    margin: 0;
}
ol.events.examine div.cow-events:not(.focused) {
    opacity: .1;
}
li.older {
    text-align: center;
    font-size: smaller;
    padding: 1rem;
    opacity: .3;
    background: var(--dashboard-widget-background);
    cursor: pointer;
}

li.older:not(.pending):hover {
    opacity: .6;
}

li.older.pending {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(255, 255, 255, 0.2) 1em,
        rgba(255, 255, 255, 0.2) 2em
        );
    animation-name: barber;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-size: 2.82em;
}

@keyframes barber {
    to {
        background-position: -2.82em;
    }
}

li.older.failed {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
        );
    background-size: 2.82em;
}
