div.tippy-popper.shepherd,
div.tippy-tooltip {
    background: transparent;
    border-radius: 0.5rem;
    overflow: visible;
}
div#tippy-47.tippy-tooltip,
div#tippy-48.tippy-tooltip {
    max-width: 520px !important;
}
.shepherd-content {
    background-color: var(--dashboard-background);
    font: inherit;
    font-size: 1rem;
    padding: 1rem;
    border: solid 0.1rem var(--signal-color);
    border-radius: 0.5rem;
    color: var(--dashboard-text-color);
    box-shadow: 0 0 0 0.5rem #0009;
}

button.shepherd-button {
    background-color: var(--dashboard-widget-background);
    color: var(--dashboard-text-color);
    font: inherit;
    border: none;
    border-radius: 0.25rem;
    box-sizing: border-box;
    min-height: 3rem;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    flex-grow: 1;
    white-space: nowrap;
}
button.shepherd-button.disabled {
    opacity: 0.5;
    pointer-events: none !important;
}
button.shepherd-button:last-child {
    margin-right: 0;
}

button.shepherd-button-primary {
    background-color: var(--button-action-color);
}

footer.shepherd-footer {
    margin-top: 1rem;
    display: flex;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target {
    outline: dashed 2px var(--signal-color);
    outline-offset: 4px;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target:not(.can-click) * {
    pointer-events: none !important;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target.can-click {
    pointer-events: all !important;
    z-index: 300;
}
@media (pointer: fine) {
    button.shepherd-button {
        min-height: 2rem;
    }
}
@media (pointer: coarse) and (max-width: 35rem) {
    .tippy-tooltip footer.shepherd-footer {
        flex-wrap: wrap;
        gap: 1rem;
    }
    .tippy-tooltip button.shepherd-button {
        margin-right: 0;
    }
}
.shepherd.tippy-popper[x-placement^="top"] .tippy-tooltip .tippy-arrow {
    border-top-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="bottom"] .tippy-tooltip .tippy-arrow {
    border-bottom-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="left"] .tippy-tooltip .tippy-arrow {
    border-left-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="right"] .tippy-tooltip .tippy-arrow {
    border-right-color: var(--signal-color);
}
