aside.pairing-list-preview {
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas: "header"
                          "pairing-main" ;
    grid-template-rows: 5rem auto;


    grid-column-start: 1;
    grid-column-end: -1;
    width: calc(100% + 2rem);
    box-sizing: border-box;
    z-index: 1;
}

aside.pairing-list-preview > header {
    grid-area: header;
    display: grid;
    grid-template-areas: "list-name"
                          "time-user"
                         "download-pdf";
    grid-template-rows: 2rem 2rem 1rem;
    padding: 0 1rem 0 1rem;
}
aside.pairing-list-preview > header > h1.list-name {
    grid-area: list-name;
    margin: .4rem 0 0 0;
    font-size: 1.8rem;
    font-weight: 300;
}
aside.pairing-list-preview > header > h1.list-name .list-id{
    font-weight: 600;
}
aside.pairing-list-preview > header > h3.time-user {
    grid-area: time-user;
    margin: .6rem 0 0 0;
    font-size: 0.9rem;
    font-weight: 300;
}

aside.pairing-list-preview > main {
    display: flex;
    flex-flow: row wrap;   
    grid-area: pairing-main;
    overflow: auto;
}

aside.pairing-list-preview > header > h2.download-pdf {
    font-size: 1rem;
    --pdf: url(./PdfChecklist.svg);
    opacity: 1;
    background-image: var(--pdf);
    align-self: center;
    padding: 0rem;
    margin-left: 1rem;
    padding-left: 3.0rem;
    background-size: 2.5rem;
    margin-bottom: 1.1rem;
    background-position: left;
    background-repeat: no-repeat;
    cursor: pointer;
    grid-area: download-pdf;
}
aside.pairing-list-preview > main > .pairing-list {
    grid-area: pairing-list;
    font-style: normal;
    font-weight: 300;
    font-size: 1.18rem;
    line-height: 1.18rem;
    display: flex;
    flex-flow: column wrap;
    list-style-type: none;
    flex: 1;
    margin: 0.4rem;
    padding: 0.6rem;
    height: 9rem;
    width: 14rem;
}

aside.pairing-list-preview > main > .pairing-list li:before{
    width: 1em;
    height: .88em;
    content: "\00a0";
    display: inline-block;
    margin-right: .25em;
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    border-radius: .29em;
    vertical-align: top;
    padding: 0.03em;
}

aside.pairing-list-preview > main > .pairing-list li{
    margin: 0.12rem;
    margin-right: 0.5rem;
}

aside.pairing-list-preview > main > .pairing-list > li.active:before {
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
}

aside.pairing-list-preview > main > .pairing-list > li.archived:before {
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    opacity: .5;
}
@media (min-width: 28rem) {
    aside.pairing-list-preview {
        height: 11rem;
        grid-template-areas: "header pairing-main";
        grid-template-columns: max-content auto;
        grid-template-rows: auto;
    }
    aside.pairing-list-preview > header {
         grid-template-rows: 2rem 3rem auto;
        /* grid-template-rows: 2rem 3rem 1rem; */
    }
    aside.pairing-list-preview > header > h2.download-pdf {
        grid-area: download-pdf;
        width: 9rem;
        margin-left: 0;
    }
    aside.pairing-list-preview > main > .pairing-list {
        margin: 0.4rem 0 0 0.5rem;
        height: 8.2rem;
    }
}
