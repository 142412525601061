article.automation {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header"
                         "image";
    grid-template-rows: 2rem auto;

    align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
    background-blend-mode: overlay, normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}
article.automation.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}
article.automation > h5{
    grid-area: header;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}

article.automation .image{
    grid-area: image;
    width: 6.4rem;
    height: 6.4rem;
    --automation: url(./AutomationUnchecked.svg);
    background-image: var(--automation);
    /*For Safari flushing problem*/
    background-image: url(./AutomationUnchecked.svg);

    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
    background-position: center 1rem;
    transition-property: background;
    transition-duration: .2s;
}
article.automation.active .image {
    --automation-checked: url(./CheckedAutomation.svg);
    background-image: var(--automation-checked);
    /*For Safari flushing problem*/
    background-image: url(./CheckedAutomation.svg);
}

article.automation.preview:not(.active) {
    opacity: .4;
}

@media(min-width: 25rem) {
    article.automation {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.automation {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.automation > h5 { font-size: 1em; }
    article.automation .image {
        background-position: center 1.5rem;
        background-size: 3.6rem;
    }
}
