main.dashboard {
    display: block;
}

main.dashboard > section {
    margin: 2.4rem 0;
    background: var(--dashboard-widget-background);
}

main.dashboard > section.cows {
    grid-area: cows;
}

main.dashboard > section.events {
    grid-area: events;
    background: var(--dashboard-background);
}

main.dashboard > section.note-form {
    grid-area: note-form;
    z-index: 3;
}
@media(max-width: 35rem) {
    /*For Safari slipping problem*/
    main.dashboard {
        overflow: hidden;
    }
    main.navigating {
        position: fixed;
    }
}
@media(min-width: 52rem) {
    main.dashboard {
        display: grid;
        grid-template-areas: "cows note-form" "cows events";
        grid-template-columns: 1fr 24rem;
        grid-template-rows: 3rem 1fr;
        grid-gap: 1rem;
        margin: 1rem;
        margin-top: 3.4rem;
        height: calc(100vh - 3.6rem);
    }

    main.dashboard > section {
        height: auto;
        margin: 0;
        overflow-y: auto;
    }

    main.dashboard > section.events > * {
        height: 100%;
    }
}

@media(min-width: 72rem) {
    main.dashboard {
        grid-template-columns: 2fr 1fr;
    }
}

::-webkit-scrollbar {
    background: var(--dashboard-widget-background);
}

::-webkit-scrollbar-track {
    background: var(--dashboard-background);
}

::-webkit-scrollbar-thumb {
    background: var(--dashboard-background);
    opacity: .5;
    border: solid 1px #777;
    border-radius: .5rem;
}

::-webkit-scrollbar-corner {
    background-color: var(--dashboard-background);
}
