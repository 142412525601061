aside.system-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas: "wicow-server internet basestation repeaters tsens-summary eartag-summary";
    /* grid-template-columns: repeat(6, auto); */
    /* grid-template-columns: 10rem 10rem 10rem 10rem 10rem 10rem; */
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    position: relative;
    grid-column-start: 1;
    grid-column-end: -1;

    width: calc(100% + 2rem);
    min-height: 12rem;
    box-sizing: border-box;
    z-index: 1;
    font-size: 0.9em;
    font-weight: 300;
}

aside.system-preview label {
    grid-area: label;
    text-align: center;
}
aside.system-preview label:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--idle-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.system-preview label.online:before,
aside.system-preview .wicow-server label:before,
aside.system-preview .internet label:before {
    background-image: var(--stable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
aside.system-preview label.offline:before,
aside.system-preview .internet label.offline:before {
    background-image: var(--unstable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}

aside.system-preview .single {
    display: grid;
    grid-template-areas: "icon" "label";
    grid-template-rows: 3fr 1fr;
}

aside.system-preview > .wicow-server {
    grid-area: wicow-server;
    background: url(./../../assets/WicowServer.svg) no-repeat;
    background-position: 50% 33%;
    background-size: 2rem;
}

aside.system-preview > .internet {
    grid-area: internet;
    background: url(./../../assets/Internet.svg) no-repeat;
    background-position: 50% 33%;
    background-size: 4rem;
}

aside.system-preview > .basestation {
    grid-area: basestation;
    background: url(./../../assets/baseStation.svg) no-repeat;
    background-position: 50% 40%;
    background-size: 2.4rem;
}
aside.system-preview > .basestation label {
    cursor: pointer;
    align-self: start;
    justify-self: center;
}
aside.system-preview .repeater .content,
aside.system-preview .basestation .content {
    display: grid;
    width: 10rem !important;
    height: 8rem !important;
    padding: 0.3rem;
}
aside.system-preview .basestation .content {
    grid-template-areas: "sensor" "status" "last-packet";
}

aside.system-preview > .repeaters {
    grid-area: repeaters;

    display: grid;
    grid-template-rows: repeat(auto, 2rem);
    grid-row-start: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

aside.system-preview > .repeaters .repeater {
    background: url(./../../assets/RepeaterSupervisor.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
    display: grid;
    max-width: 6.4rem;
}
aside.system-preview > .repeaters .repeater.supervisor {
    background-image: url(./../../assets/RepeaterSupervisor.svg);
}

aside.system-preview > .repeaters .repeater label {
    cursor: pointer;
    align-self: start;
}

aside.system-preview > .tsens-summary,
aside.system-preview > .eartag-summary {
    display: flex;
    flex-direction: column;
    align-self: center;
}
aside.system-preview > .tsens-summary span strong,
aside.system-preview > .eartag-summary span strong {
    display: inline-block;
    width: 2rem;
    text-align: right;
    margin-right: 0.3rem;
}

aside.system-preview > .tsens-summary span,
aside.system-preview > .eartag-summary span {
    padding-left: 0.8rem;
}

aside.system-preview > .tsens-summary span:first-child {
    background: url(./../../assets/Tsens.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
}
aside.system-preview > .eartag-summary span:first-child {
    background: url(./../../assets/EarTag.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
}
aside.system-preview .repeater .content {
    grid-template-areas: "sensor" "status" "last-packet" "daily-traffic";
    grid-template-rows: 1fr 2fr 2fr 2fr;
}

aside.system-preview .repeater .content .sensor-data,
aside.system-preview .basestation .content .basestation-data {
    display: flex;
    flex-direction: row-reverse;
}
aside.system-preview .repeater .content .sensor-data label {
    margin-left: 0.2rem;
}

aside.system-preview .repeater .content .rssi:before {
    background: url(./Signal3.svg) no-repeat;
    width: 1.4rem;
    height: 1.4rem;
    background-size: 1.4rem;
    background-position: center;
    margin: 0;
}
aside.system-preview .repeater .content .temperature:before,
aside.system-preview .basestation .content .temperature:before {
    background: url(./../../assets/Thermometer.svg) no-repeat;
    width: 1.4rem;
    height: 1.4rem;
    background-size: 1.4rem;
    background-position: right;
    background-position-x: 0.3rem;
    margin: 0;
}
aside.system-preview .basestation .content .temperature,
aside.system-preview .repeater .content .temperature {
    padding-right: 0.6rem;
}

aside.system-preview .repeater .content .rssi,
aside.system-preview .repeater .content .temperature,
aside.system-preview .basestation .content .temperature {
    grid-area: sensor;
    justify-self: end;
    cursor: default;
}
aside.system-preview .repeater .content .status,
aside.system-preview .basestation .content .status {
    grid-area: status;
    margin-left: 0.2rem;
    justify-self: left;
    cursor: default;
}
aside.system-preview .repeater .content .status > span > strong,
aside.system-preview .basestation .content .status > span > strong {
    margin-right: 0.3rem;
}
aside.system-preview .repeater .content .last-packet,
aside.system-preview .basestation .content .last-packet {
    grid-area: last-packet;
    margin-left: 1.6rem;
}
aside.system-preview .repeater .content .last-packet > span,
aside.system-preview .basestation .content .last-packet > span {
    display: block;
}
aside.system-preview .repeater .content .last-packet > span > strong,
aside.system-preview .basestation .content .last-packet > span > strong {
    margin-right: 0.3rem;
}
aside.system-preview .repeater .content .daily-traffic {
    grid-area: daily-traffic;
    margin-left: 1.6rem;
}
aside.system-preview .repeater .content .daily-traffic > span {
    display: block;
}

@media (min-width: 20rem) and (max-width: 40rem) {
    aside.system-preview {
        min-height: 16rem;
        grid-template-areas:
            "wicow-server internet basestation"
            "repeaters tsens-summary eartag-summary";
        grid-template-columns: repeat(3, auto);
    }
    aside.system-preview > .repeaters {
        grid-area: repeaters;
        margin-left: 1.4rem;
    }
    aside.system-preview > .repeaters .repeater {
        min-height: 2rem;
    }
}
