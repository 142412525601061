main.farm-metrics {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas: "header close-button"
                         "body body";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, .5);
    overflow: hidden;
}
main.farm-metrics .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.farm-metrics h1.header{
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.farm-metrics > .body {
    grid-area: body;
    margin: 0 .5rem 0 .5rem;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* display: grid; */
}
main.farm-metrics > .body > div {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--dashboard-background);
}
main.farm-metrics > .body::-webkit-scrollbar{
    display: none;
}

main.farm-metrics > .body > div > h3 {
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 .5rem 0 .5rem;
}

main.farm-metrics > .body > div > h3 > span {
    display: block;
}

main.farm-metrics > .body > div > h3 > span:first-child {
    margin-bottom: 1rem;
}

main.farm-metrics > .body > p {
    margin-left: 1rem;
}

main.farm-metrics p.error{
    opacity: .6;
    font-weight: 300;
}

@media (min-width: 52rem) {
    main.farm-metrics {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
        max-height: 90vh;
    }
    main.farm-metrics  > .farms > .farm-list{
        height: 42.7rem;
        border-radius: 0 0 0 1rem;
    }
}